import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function AllowedUser({ user, onDelete, onAccessChange }) {

    const [access, setAccess] = useState(user.can_write ? "write" : "read");

    const handleAccessChange = (e) => {
        setAccess(e.target.value);
        onAccessChange(user, e.target.value);
    }

    return (
        <div className="allowed-user-container">
            <FontAwesomeIcon icon="fa-solid fa-circle-user" style={{ height: 24, width: 24, marginRight: 12, marginLeft: 4 }} />
            <div className="user-infos">
                <span className="allowed-user-name">{user.name + " "}<strong style={{ display: 'inline-block' }}>{user.first_name}</strong></span>
                <span className="allowed-user-email">{user.email}</span>
            </div>
            <select className="allowed-user-select" value={access} onChange={handleAccessChange}>
                <option value="read">Consultation uniquement</option>
                <option value="write">Consultation et modification</option>
            </select>
            <div className="allowed-user-remove" onClick={() => onDelete(user)}>
                <FontAwesomeIcon icon="fa-solid fa-times" />
            </div>
        </div>
    )
}

export default AllowedUser;