import padelClubs from '../assets/padel-clubs.json'

const PadelServices = {

    // returns the name of the club
    // replace spaces with underscores
    getClubNameFromId: (clubId) => {
        for (let i = 0; i < padelClubs.length; i++) {
            if (padelClubs[i].id === clubId) {
                return padelClubs[i].name
            }
        }
    },

    getResByDOW: async (clubIds, interval) => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/res-by-dow', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clubIds: clubIds ? clubIds : null, start: interval ? interval.start : null, end: interval ? interval.end : null })
            })
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    },

    getResByTimeslot: async (clubId, interval, indoor) => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/res-by-timeslot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clubId: clubId ? clubId : null, start: interval ? interval.start : null, end: interval ? interval.end : null, indoor: indoor })
            })
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    },

    getFillRate: async (clubIds, interval) => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/fill-rate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clubIds: clubIds ? clubIds : null, start: interval ? interval.start : null, end: interval ? interval.end : null })
            })
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    },

    getResByWeek: async (clubId, interval) => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/res-by-week', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clubId: clubId ? clubId : null, start: interval ? interval.start : null, end: interval ? interval.end : null })
            })
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    },

    getAveragePrice: async () => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/avg-price', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    },

    getCourtAmountByClubs: async () => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/courts-amount-by-clubs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    },

    getCourtAmountByType: async () => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/courts-amount-by-type', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    },

    getPadelClubs: async () => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/clubs')
                .then(response => response.json())
                .then(data => {
                    let clubs = data;
                    for (let i = 0; i < clubs.length; i++) {
                        clubs[i].schedule = JSON.parse(clubs[i].schedule)
                    }
                    resolve(clubs)
                })
                .catch(err => reject(err))
        })
    },

    getPadelCourts: async () => {
        return new Promise((resolve, reject) => {
            fetch('https://app.idelia.org:3001/api/padel/courts')
                .then(response => response.json())
                .then(data => resolve(data))
                .catch(err => reject(err))
        })
    }
}

export default PadelServices;