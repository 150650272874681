import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function InputText({ clearButton, onClick, labelFor, text, onChange, onEnter, icon, width, margin, placeHolder, id, className }) {

    const inputStyle = {
        height: '48px',
        backgroundColor: 'white',
        borderRadius: '4px',
        border: 'none',
        color: 'rgb(var(--black-8))',
        fontSize: '1rem',
        padding: "0px",
        width: '100%'
    }

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        width: width ? width : '384px',
        backgroundColor: 'white',
        border: '1px solid rgb(var(--black-2))',
        borderRadius: '4px',
        margin: margin ? margin : '0px'
    }

    const inputContainerStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 8px'
    }

    const iconStyle = {
        marginLeft: '8px',
        marginRight: '8px'
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onEnter(e.target.value)
        }
    }

    const handleChange = (e) => {
        onChange(e.target.value)
    }

    return (
        <div
            className={className ? className : ''}
            id={id ? id : ''}
            style={containerStyle}>
            {icon ? <FontAwesomeIcon icon={icon} height='32px' style={iconStyle} /> : "pas d'icone"}
            <div style={inputContainerStyle}>
                <input
                    onKeyDown={onKeyDown}
                    onClick={onClick}
                    type="text"
                    style={inputStyle}
                    value={text}
                    onChange={handleChange}
                    placeholder={placeHolder ? placeHolder : ''} />
                {(clearButton && text !== '')
                    ? <FontAwesomeIcon icon="fa-regular fa-circle-xmark" color='rgb(var(--black-6))' height='32px' style={{ cursor: 'pointer', margin: '0px 8px' }} onClick={() => { onEnter('') }} />
                    : ''
                }
            </div>
        </div>
    )
}

export default InputText;