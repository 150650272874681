import React, { useEffect, useState } from "react";
import { createRoot } from 'react-dom/client'
import { useMap } from "react-map-gl";

import rulerIcon from '../assets/images/ruler.png'
import '../style/ruler.css'
import mapboxgl from "mapbox-gl";

import { polygon, length, area, distance } from '@turf/turf';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CadastreServices from "../services/cadastre";
import ElevationGraph from "./elevation-graph";
import { useGlobalState } from "../pages/app-container";

function Measure({ onActiveChange, onChange }) {

    const RulerPopup = ({ distance, area, onClose, handleAltiClick }) => {

        const markers = document.getElementsByClassName("ruler-marker");

        return (
            <div className="ruler-popup-container">
                <FontAwesomeIcon icon="fa-solid fa-close" id="ruler-popup-close" onClick={onClose} />
                <div className="ruler-popup-header">
                    <img src={rulerIcon} alt="Icone de règle" height="24px" />
                    <h3>Mesure</h3>
                </div>
                <div className="ruler-popup-content">
                    <span className="ruler-popup-label">Distance : </span>
                    <span className="ruler-popup-distance">{distance.toFixed(2)} m</span>
                </div>
                <div className="ruler-popup-content">
                    <span className="ruler-popup-label">Aire : </span>
                    <span className="ruler-popup-distance">{area.toFixed(2)} m²</span>
                </div>
                {markers && markers.length === 2 && (
                    <div className="ruler-popup-content">
                        <span onClick={handleAltiClick} className="ruler-altimetrie">Altimétrie</span>
                    </div>
                )}
            </div>
        )
    }

    const { current: map } = useMap();

    const [isActive, setIsActive] = useState(false);
    const [distanceM, setDistance] = useState(0);
    const [aire, setAire] = useState(0);

    const [showHoverLine, setShowHoverLine] = useState(true);

    const [rulerLine, setRulerLine] = useGlobalState('rulerLine');
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        map.on('click', handleMapClick);
        map.on('contextmenu', handleContextMenu);
        map.on('mousemove', handleMouseMove);

        return () => {
            map.off('click', handleMapClick);
            map.off('contextmenu', handleContextMenu);
            map.off('mousemove', handleMouseMove);
        }
    });

    useEffect(() => {
        /*
        removeAllMarkers();

        if (rulerLine && rulerLine.length > 0) {
            rulerLine.forEach(location => {
                createMarker(location);
            });
        }
        */

        markers.forEach(marker => {
            marker.on('drag', handleMarkerDrag);

            return () => {
                marker.off('drag', handleMarkerDrag);
            }
        });

        if (rulerLine !== null) {

            if (rulerLine.length < 2) {
                onChange({
                    distance: 0,
                    area: 0
                })
            } else if (rulerLine.length === 2) {
                setDistance(distance([rulerLine[0].lng, rulerLine[0].lat], [rulerLine[1].lng, rulerLine[1].lat], { units: 'meters' }));
                onChange({
                    distance: distance([rulerLine[0].lng, rulerLine[0].lat], [rulerLine[1].lng, rulerLine[1].lat], { units: 'meters' }),
                    area: 0
                })
            }
            else if (rulerLine.length > 2) {
                // add the first location to the end of the array
                let localLocations = [...rulerLine, rulerLine[0]];
                const selection = polygon([localLocations.map(locations => [locations.lng, locations.lat])]);
                setDistance(length(selection, { units: 'meters' }));
                setAire(area(selection));
                onChange({
                    distance: length(selection, { units: 'meters' }),
                    area: area(selection)
                })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rulerLine]);

    const openProfilAltimetrique = () => {
        CadastreServices.getProfileAltimetrique(rulerLine[0], rulerLine[1]).then((response) => {

            const popupWindow = window.open(
                '',
                '_blank',
                'width=500,height=400,resizable,scrollbars=yes'
            );
            popupWindow.document.write('<html><head><title>Popup Window</title></head><body style="margin: 0"><div id="popup-root"></div></body></html>');
            popupWindow.document.close();

            const elevationContainer = popupWindow.document.getElementById('popup-root');
            const root = createRoot(elevationContainer);
            root.render(<ElevationGraph distance={distanceM} elevationData={response.elevations} />);

            createElevationsMarkers(response.elevations);
        })
    }

    const handleMouseMove = (e) => {
        if (isActive && showHoverLine) {
            if (rulerLine && rulerLine.length > 0) {
                const markers = document.getElementsByClassName("ruler-marker");
                let newRulerLine = [...rulerLine];
                newRulerLine[markers.length] = e.lngLat;
                setRulerLine(newRulerLine);
            }
        }
    }

    const createElevationsMarkers = (elevations) => {
        let i = 0;
        elevations.forEach(elevation => {
            let markerElt = document.createElement("div");
            markerElt.className = "elevation-marker";
            markerElt.id = "elevation-marker-" + i;

            let marker = new mapboxgl.Marker({ element: markerElt })
            marker.setLngLat([elevation.lon, elevation.lat]);
            marker.addTo(map.getMap());
            i++;
        });
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        if (isActive && showHoverLine && rulerLine.length > 0) {
            setRulerLine(rulerLine.slice(0, -1));
            setShowHoverLine(false);
        }
    }

    const handleMarkerDrag = (e) => {
        const markerId = e.target.getElement().id.split('-')[2];
        const newRulerLine = [...rulerLine];
        newRulerLine[markerId] = e.target.getLngLat();
        setRulerLine(newRulerLine);
    }

    const createMarker = (location, index) => {
        let markerElt = document.createElement("div");
        markerElt.className = "ruler-marker";
        markerElt.id = "ruler-marker-" + index;
        markerElt.innerText = index + 1;

        let marker = new mapboxgl.Marker({ element: markerElt, draggable: true })
        marker.setLngLat([location.lng, location.lat]);
        marker.addTo(map.getMap());
        setMarkers([...markers, marker]);
    }


    const removeAllMarkers = () => {

        markers.forEach(marker => {
            marker.remove();
        });

        setMarkers([]);

        /*
        const markers = document.getElementsByClassName("ruler-marker");
        while (markers.length > 0) {
            markers[0].parentNode.removeChild(markers[0]);
        }
        */

        const elevationMarkers = document.getElementsByClassName("elevation-marker");
        while (elevationMarkers.length > 0) {
            elevationMarkers[0].parentNode.removeChild(elevationMarkers[0]);
        }
    }

    const handleMapClick = (e) => {
        if (isActive) {
            setShowHoverLine(true);
            setRulerLine([...rulerLine, e.lngLat]);
            let markers = document.getElementsByClassName("ruler-marker");
            createMarker(e.lngLat, markers !== null ? markers.length : 0);
        }
    }

    const handleClick = (e) => {
        if (e.target.id === "ruler-icon") {
            setIsActive(!isActive);
            onActiveChange(!isActive);
            setRulerLine([]);
            removeAllMarkers();
            setDistance(0);
            setAire(0);
        }
    }

    const handleClose = () => {
        setIsActive(false);
        onActiveChange(false);
        setRulerLine([]);
        removeAllMarkers();
        setDistance(0);
        setAire(0);
    }

    return (
        <div id="ruler" className={"ruler " + (isActive && "active")} onClick={handleClick}>
            <img id="ruler-icon" src={rulerIcon} alt="" height="32px" />
            {isActive && <RulerPopup distance={distanceM} area={aire} onClose={handleClose} handleAltiClick={openProfilAltimetrique} />}
        </div>
    )

}

export default Measure;