import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Navbar({ items, onChange, activeTab }) {


    return (
        <>
            <nav className="padel-component padel-navbar">
                <h1 style={{ marginBottom: 32 }}>Padel</h1>
                {items.map((item, index) => (
                    <li key={index} onClick={() => onChange(item.id)} className={"padel-navbar-item" + (item.id === activeTab.id ? " active" : "")} id={item.id} >
                        <FontAwesomeIcon icon={item.icon} className="navbar-icon" />
                        {item.label}
                    </li>
                ))}
            </nav>
        </>
    )
}

export default Navbar