import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/button";
import { useEffect, useState } from "react";
import * as turf from '@turf/turf';

import CadastreServices from '../services/cadastre';
//import DVFServices from '../services/DVF';
import Loader from "../components/loader";
import ZonePLU from "../components/zone-plu";
import DVFServices from "../services/DVF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PEB from '../assets/geojson/peb.json';


function ParcelleDetails() {

    let navigate = useNavigate();
    const { plotId } = useParams();

    const [plot, setPlot] = useState(null);
    const [zones, setZones] = useState(null);
    const [dvf, setDvf] = useState(null);
    const [zonePEB, setZonePEB] = useState(null);

    const [dvfSection, setDvfSection] = useState(null);

    useEffect(() => {
        CadastreServices.getPlotDetails(plotId.substring(10, 14), plotId.substring(8, 10), plotId.substring(5, 8), null, plotId.substring(0, 5)).then((data) => {
            if (data && data.features && data.features.length > 0) {
                setPlot(data.features[0])
            } else {
                setPlot("no data")
            }
        })

        DVFServices.getDVFByPlot(plotId).then((data) => {
            setDvf(data)
        })

        DVFServices.getDVFBySection(plotId).then((data) => {
            setDvfSection(data)
        })

    }, [plotId])

    useEffect(() => {
        if (plot && plot !== "no data") {
            getZonesParcelles();
            getZonesPEB();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plot])

    const superficieStyle = {
        backgroundColor: 'rgb(var(--primary))',
        color: 'white',
        padding: 8,
        borderRadius: 8,
        fontWeight: 'bold',
        width: "fit-content",
        marginTop: 8,
        marginRight: 8
    }

    console.log("DVF section :", dvfSection)

    const handleGeoportailClick = () => {
        let centerOfPlot = turf.centerOfMass(plot)
        let lngLat = { lng: centerOfPlot.geometry.coordinates[0], lat: centerOfPlot.geometry.coordinates[1] }
        CadastreServices.openGeoportailUrbanismeMap(lngLat);
    }

    const getZonesParcelles = () => {
        CadastreServices.getZonesPLUByInsee(plot.properties.code_insee).then((data) => {
            if (data && data.plu && data.plu.features && data.plu.features.length > 0) {
                let zonesPLU = [];
                let plu = data.plu;

                plu.features.forEach((zone) => {
                    let isInside = false;
                    plot.geometry.coordinates[0][0].forEach((point) => {
                        if (turf.booleanPointInPolygon(turf.point(point), zone)) {
                            isInside = true;
                        }
                    })
                    if (isInside) zonesPLU.push({ label: zone.properties.libelong !== null ? zone.properties.libelong.replaceAll("'", " ") : zone.properties.libelle, typezone: zone.properties.typezone, zone: zone.properties.libelle })
                })
                setZones(zonesPLU);
            } else {
                setZones([]);
            }
        })

    }

    const getZonesPEB = () => {
        let zonesPEB = [];
        PEB.features.forEach((zone) => {
            let isInside = false;
            plot.geometry.coordinates[0][0].forEach((point) => {
                if (turf.booleanPointInPolygon(turf.point(point), zone)) {
                    isInside = true;
                }
            })
            if (isInside) zonesPEB.push(zone.properties.ZONE)
        })
        setZonePEB(zonesPEB.sort()[0]);
    }

    return (
        <>
            <Button text="Retour" icon="fa-solid fa-chevron-left" onClick={() => { navigate(-1) }} className="primary" extraStyles={{ marginLeft: 8, marginBottom: 8 }} />
            {plot !== null && plot !== "no data" ? (
                <>
                    <h1>Parcelle {(plot.properties.com_abs === "000" ? "" : plot.properties.com_abs) + " " + plot.properties.section + " " + plot.properties.numero}</h1>
                    <div className="parcelle-container">
                        <div style={{ marginBottom: '16px' }}>
                            <span style={superficieStyle}>{plot.properties.contenance} m²</span>
                            <span>
                                {plot.properties.nom_com}
                                <span style={{ color: 'rgb(var(--black-7))' }}> (INSEE {plot.properties.code_insee})</span>
                            </span>
                        </div>
                        <Button text="Voir sur le Géoportail" onClick={handleGeoportailClick} />
                        <div className="parcelles-zones">
                            <h2>Zones</h2>
                            {zones === null ? <Loader /> : (
                                <>
                                    {zones.length > 0
                                        ? zones.sort((a, b) => {
                                            if (a.typezone < b.typezone) { return -1; }
                                            if (a.typezone > b.typezone) { return 1; }
                                            return 0;
                                        }).map((zone) => {
                                            return <ZonePLU zone={zone} />
                                        })
                                        : <p>Aucune zone</p>
                                    }
                                </>
                            )}
                        </div>
                        {zonePEB !== null && (
                            <div className="parcelles-zones">
                                <h2>Plan d'Exposition au Bruit</h2>
                                <h3>Parcelle sous courbe <span className="primary-bold">{zonePEB}</span></h3>
                            </div>
                        )}
                        <div className="parcelles-zones">
                            <h2>Données DVF de la parcelle {(plot.properties.com_abs === "000" ? "" : plot.properties.com_abs) + " " + plot.properties.section + " " + plot.properties.numero}</h2>
                            {dvf === null ? <Loader /> : (
                                <>
                                    {dvf.length > 0
                                        ? dvf.map((dvf) => {
                                            return (
                                                <div className="dvf-item">
                                                    <div className="dvf-header">
                                                        <FontAwesomeIcon icon="fa-solid fa-file-signature" />
                                                        <div className="dvf-header-right">
                                                            <span className="dvf-bold">{dvf.nature_mutation + " / " + dvf.valeur_fonciere + " €"}</span>
                                                            <span className="dvf-info">{dvf.date_mutation}</span>
                                                            <span className="dvf-small">{dvf.adresse_numero + " " + dvf.adresse_nom_voie}</span>
                                                        </div>
                                                    </div>
                                                    <div className="dvf-content">
                                                        {dvf.surface_reelle_bati !== "" && (
                                                            <div className="dvf-row">
                                                                <FontAwesomeIcon icon="fa-solid fa-home" />
                                                                <div className="dvf-right">
                                                                    <span className="dvf-bold">{dvf.surface_reelle_bati} m²</span>
                                                                    <span className="dvf-info">{dvf.type_local + " / " + dvf.nombre_pieces_principales + " p"}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {dvf.surface_terrain !== "" && (
                                                            <div className="dvf-row">
                                                                <FontAwesomeIcon icon="fa-solid fa-tree" />
                                                                <div className="dvf-right">
                                                                    <span className="dvf-bold">{dvf.surface_terrain} m²</span>
                                                                    <span className="dvf-info">{dvf.nature_culture}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <p>Aucune transaction enregistrée</p>
                                    }

                                </>
                            )}
                        </div>
                        {dvfSection !== null && dvfSection !== [] && (
                            <div className="parcelles-zones">
                                <h2>Données DVF de la section {(plot.properties.com_abs === "000" ? "" : plot.properties.com_abs) + " " + plot.properties.section}</h2>
                                {dvfSection === null ? <Loader /> : (
                                    <>
                                        {dvfSection.length > 0
                                            ? dvfSection.map((dvfItem) => {
                                                return (
                                                    <div className="dvf-item">
                                                        <div className="dvf-header">
                                                            <FontAwesomeIcon icon="fa-solid fa-file-signature" />
                                                            <div className="dvf-header-right">
                                                                <span className="dvf-bold">{dvfItem.nature_mutation + " / " + dvfItem.valeur_fonciere + " €"}</span>
                                                                <span className="dvf-info">{dvfItem.date_mutation}</span>
                                                                <span className="dvf-small">{dvfItem.adresse_numero + " " + dvfItem.adresse_nom_voie}</span>
                                                            </div>
                                                        </div>
                                                        <div className="dvf-content">
                                                            {dvfItem.surface_reelle_bati !== "" && (
                                                                <div className="dvf-row">
                                                                    <FontAwesomeIcon icon="fa-solid fa-home" />
                                                                    <div className="dvf-right">
                                                                        <span className="dvf-bold">{dvfItem.surface_reelle_bati} m²</span>
                                                                        <span className="dvf-info">{dvfItem.type_local + " / " + dvfItem.nombre_pieces_principales + " p"}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {dvfItem.surface_terrain !== "" && (
                                                                <div className="dvf-row">
                                                                    <FontAwesomeIcon icon="fa-solid fa-tree" />
                                                                    <div className="dvf-right">
                                                                        <span className="dvf-bold">{dvfItem.surface_terrain} m²</span>
                                                                        <span className="dvf-info">{dvfItem.nature_culture}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : <p>Aucune transaction enregistrée pour la section</p>
                                        }

                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </>

            ) : (<>{plot === "no data" ? <p>Parcelle non trouvée</p> : <Loader />}</>)
            }
        </>
    )
}

export default ParcelleDetails;