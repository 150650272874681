const URL_API = "https://app.idelia.org:3001";
//const URL_API = "http://localhost:3001";

const CollectionServices = {

    // Get own collections
    getOwnCollections: async (token) => {
        const response = await fetch(URL_API + "/api/collections/own", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.status !== 200) {
            return response;
        } else {
            let rep = {};
            rep.status = response.status;
            rep.collections = await response.json();
            return rep;
        }
    },

    // get shared collection
    getSharedCollections: async (token) => {
        const response = await fetch(URL_API + "/api/collections/shared", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.status !== 200) {
            return response;
        }
        let rep = {};
        rep.status = response.status;
        rep.collections = await response.json();
        return rep;
    },

    // get collection by uuid
    getCollectionByUUID: async (uuid, token) => {
        const response = await fetch(URL_API + "/api/collections/" + uuid, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.status !== 200) {
            return response;
        }
        let rep = {};
        rep.status = response.status;
        rep.collection = await response.json();
        return rep;
    },

    // update with PUT method
    updateCollectionDescription: async (uuid, description, token) => {
        try {
            const response = await fetch(URL_API + "/api/collections/" + uuid + "/description", {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    description: description
                })
            });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    // update fav with PUT method
    updateCollectionFav: async (uuid, fav, token) => {
        try {
            const response = await fetch(URL_API + "/api/collections/" + uuid + "/fav", {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fav: fav
                })
            });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    // update name with PUT method
    updateCollectionName: async (uuid, name, token) => {
        try {
            const response = await fetch(URL_API + "/api/collections/" + uuid + "/name", {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name
                })
            });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    // update collection
    updateCollection: async (collection, token) => {
        try {
            const response = await fetch(URL_API + "/api/collections/" + collection.uuid, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "collectionUUID": collection.uuid,
                    "name": collection.name,
                    "description": collection.description,
                    "plots": collection.plots,
                })
            });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    // create collection
    createCollection: async (collection, token) => {
        try {
            const response = await fetch(URL_API + "/api/collections", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    "name": collection.name,
                    "description": collection.description,
                    "plots": collection.plots,
                    "sharedWith": collection.sharedWith
                })
            });
            if (response.status !== 201) {
                throw new Error(response.statusText);
            }
            return await response.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    updateCollectionUsers: async (collectionUUID, users, token) => {
        console.log(JSON.stringify(users));
        try {
            const response = await fetch(URL_API + "/api/collections/" + collectionUUID + "/users", {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "shared_with": users
                })
            });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    // delete collection
    deleteCollection: async (collectionUUID, token) => {
        try {
            const response = await fetch(URL_API + "/api/collections/" + collectionUUID, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            if (response.status !== 200) {
                throw new Error(response.statusText);
            }
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
}

export default CollectionServices;