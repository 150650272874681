import './App.css';
import './style/colors.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import Home from './pages/home';


function App() {

  library.add(fas, far)

  return (
    <Home />
  );
}

export default App;
