function ZonePLU({ zone }) {

    const getZoneColor = () => {
        switch (zone.typezone) {
            case 'A':
                return 'yellow';
            case 'AUc':
                return "#900";
            case 'AUs':
                return "#900";
            case 'N':
                return "green";
            case 'U':
                return "red";
            default:
                return "black";
        }
    }

    const colorStyle = {
        backgroundColor: getZoneColor(),
        minWidth: 16,
        minHeight: 16,
        borderRadius: 4,
        marginRight: 8,
    }

    const zonePluStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        margin: "8px 0px"
    }

    const labelStyle = {
        fontSize: "0.8rem",
        color: "rgb(var(--black-8))"
    }

    return (
        <div className="zone-plu" style={zonePluStyle}>
            <div style={colorStyle}></div>
            <div style={labelStyle}>{zone.label}</div>
        </div>
    )

}

export default ZonePLU;