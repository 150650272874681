const SearchServices = {

    searchByPlot: async (plot) => {
        const response = await fetch('https://apicarto.ign.fr/api/cadastre/parcelle?section=' + plot.section + '&numero=' + plot.number);
        const data = await response.json();
        return data;
    },

    searchByAddress: async (address) => {
        const response = await fetch('https://api-adresse.data.gouv.fr/search/?q=' + address);
        const data = await response.json();
        return data;
    }
}

export default SearchServices;