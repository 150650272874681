import { useEffect, useState } from "react";
import PadelServices from "../../services/padel";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CourtAmount = ({ width, onClubClick }) => {

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        refreshGraph()
    }, []);

    const refreshGraph = () => {
        if (isRefreshing) return
        setIsRefreshing(true)
        fetchData()
    }

    const fetchData = async () => {
        PadelServices.getCourtAmountByClubs().then(res => {
            if (res && res.length > 0) setData(res)
            setIsRefreshing(false)
        })
    }

    const handleBarClick = (e) => {
        if (!e.activePayload || e.activePayload.length === 0) return
        let id = e.activePayload[0].payload.id
        onClubClick(id)
    }

    return (
        <div className="padel-component">
            <div className="padel-component-header">
                <h2>Nombre de courts par club</h2>
                <FontAwesomeIcon className="clickable-icon" icon="fa-solid fa-arrows-rotate" onClick={refreshGraph} spin={isRefreshing} />
            </div>
            {isRefreshing ? <div className="loading">Chargement...</div>
                : <BarChart data={data} width={width} height={300} onClick={handleBarClick} >
                    <Tooltip />
                    <XAxis dataKey={"name"} />
                    <YAxis />
                    <Bar fill="rgb(0, 136, 204)" dataKey="courts" />
                </BarChart>
            }
        </div>
    )

}

export default CourtAmount