import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import collectionIcon from '../assets/images/collection.png'
import UtilsServices from '../services/utils';
import '../style/list-items.css'
import IconButton from './icon-button';

function CollectionListItem({ collection, onClick, onFavClick }) {

    const handleFavClick = (e) => {
        e.stopPropagation();
        onFavClick(collection.uuid, collection.fav === 0 ? 1 : 0);
    }

    // convert collection.last_edited to displayable date string using Date()
    const date = new Date(collection.last_edited).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });

    return (
        <div className="collection-list-item-container" onClick={onClick}>
            <div className="left">
                <img width={34} height={34} style={{ padding: '0px 16px' }} src={collectionIcon} alt={"Icône de la collection " + collection.name} />
                <div className="collection-info">
                    <h3 title={collection.name} style={{ margin: 0 }}>{UtilsServices.truncateStringToFit(collection.name, "175", "Source Sans Pro Bold")}</h3>

                    <span style={{ fontSize: 12, fontFamily: "Source Sans Pro" }}><strong>{collection.plots.length} parcelles</strong> - <span style={{ fontFamily: "Source Sans Pro Italic", color: "rgb(var(--black-7))" }}>Modifiée le {date}</span></span>
                </div>
            </div>
            <div className="right">
                <IconButton onClick={handleFavClick} icon={collection.fav === 0 ? "fa-regular fa-bookmark" : "fa-solid fa-bookmark"} />
                <FontAwesomeIcon icon='fa-solid fa-chevron-right' style={{ margin: '0px 8px' }} color='rgb(var(--black-6))' />
            </div>
        </div >
    )
}

export default CollectionListItem;