import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useGlobalState } from "../pages/app-container";
import UsersServices from "../services/users";
import Button from "./button";

function NewAccountPopup({ user, onAccountCreated, visible }) {

    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');

    const [token, setToken] = useGlobalState('token');
    const { getAccessTokenSilently } = useAuth0();

    const [isLoading, setIsLoading] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);

    useEffect(() => {
        if (!token) {
            getAccessTokenSilently().then((tokenRep) => {
                setToken(tokenRep);
            })
        }
    })

    const handleInputChange = (type, value) => {
        if (type === 'name') {
            setName(value);
            if (value.replaceAll(' ', '') !== '' && firstName.replaceAll(' ', '') !== '') setAllowSubmit(true);
            else setAllowSubmit(false);
        } else if (type === 'firstName') {
            setFirstName(value);
            if (value.replaceAll(' ', '') !== '' && name.replaceAll(' ', '') !== '') setAllowSubmit(true);
            else setAllowSubmit(false);
        }
    }

    const createAccount = () => {

        if (allowSubmit) {
            setIsLoading(true);
            UsersServices.createAccount(user.sub, user.email, name, firstName, token).then((response) => {
                if (response === "Account created") onAccountCreated();
                else { } // TODO toast erreur
            });
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') createAccount();
    }

    if (visible) {
        return (
            <div className="overlay">
                <div className="new-account-popup">
                    <h1>Bienvenue sur Idélia</h1>
                    <span className="sub-title">Veuillez saisir votre prénom et votre nom pour continuer</span>
                    <label htmlFor="first-name">Prénom</label>
                    <input type="text" id="first-name" value={firstName} onChange={(e) => handleInputChange("firstName", e.target.value)} />
                    <label htmlFor="name">Nom</label>
                    <input onKeyDown={handleEnter} type="text" id="name" value={name} onChange={(e) => handleInputChange("name", e.target.value)} />
                    <Button icon="fa-solid fa-user" extraStyles={{ alignSelf: 'flex-end', marginTop: 11, marginRight: 0 }} disabled={!allowSubmit} text="S'inscrire" className="primary" isLoading={isLoading} onClick={createAccount} />
                </div>
            </div>
        )
    } else return null;

}

export default NewAccountPopup;