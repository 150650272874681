import PadelServices from "../../services/padel";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chartColors from '../../assets/chart-colors.json'
import { useEffect, useState } from "react";

const ResByTimeslot = ({ width, clubIds, data, refreshing, onRefresh, indoor }) => {

    const [resCount, setResCount] = useState(0)

    useEffect(() => {
        if (clubIds && clubIds.length === 1) {
            let count = 0;
            data.forEach(d => {
                count += d[PadelServices.getClubNameFromId(clubIds[0])]
            })
            setResCount(count)
        } else {
            setResCount(0)
        }
    }, [data, clubIds])

    return (
        <div className="padel-component">
            <div className="padel-component-header">
                <h2>Reservations par créneau horaire {indoor ? "intérieur" : "extérieur"} {resCount > 0 && "(" + resCount + ")"}</h2>
                <FontAwesomeIcon className="clickable-icon" icon="fa-solid fa-arrows-rotate" onClick={onRefresh} spin={refreshing} />
            </div>
            {refreshing ? <div className="loading">Chargement...</div>
                : <BarChart data={data} width={width} height={300} >
                    <Tooltip />
                    <XAxis dataKey={"Créneau"} />
                    <YAxis />
                    {clubIds && clubIds.length > 0 && clubIds.map((clubId, index) => (
                        <Bar key={index} fill={chartColors[index % 5].color} dataKey={PadelServices.getClubNameFromId(clubId)} />
                    ))}
                    {!clubIds || clubIds.length === 0 ?
                        <Bar fill="rgb(0, 136, 204)" dataKey="Tout club" />
                        : null
                    }
                </BarChart>
            }
        </div>
    )

}

export default ResByTimeslot