import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";

import useWindowDimensions from "../services/window-dimensions";

import logoIdelia from "../assets/images/idelia.jpg";
import Mapbox from "../components/mapbox";
import SearchBar from "../components/search-bar";
import UserIcon from "../components/user-icon";
import { createGlobalState } from "react-hooks-global-state";
import Button from "../components/button";
import { MapProvider } from "react-map-gl";
import UsersServices from "../services/users";
import NewAccountPopup from "../components/new-account-popup";

const initialState = {
    openedCollection: null,
    searchCollection: null,
    token: null,
    activeTabIndex: 0,
    rulerLine: null,
    hideCollectionTab: false,
};
export const { useGlobalState } = createGlobalState(initialState);

function AppContainer() {
    const { height } = useWindowDimensions();

    const mapRef = useRef();
    const [appState, setAppState] = useState(true);
    const [token, setToken] = useGlobalState("token");

    const [fullScreen, setFullScreen] = useState(false);

    const [showCreateAccountPopup, setShowCreateAccountPopup] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [searchCollection, setSearchCollection] =
        useGlobalState("searchCollection");

    const [hideCollectionTab, setHideCollectionTab] =
        useGlobalState("hideCollectionTab");

    const {
        loginWithRedirect,
        isLoading,
        isAuthenticated,
        user,
        logout,
        getAccessTokenSilently,
    } = useAuth0();

    let navigate = useNavigate();
    let pathname = window.location.pathname;

    const containerStyle = {
        backgroundColor: "rgb(var(--secondary))",
        width: "512px",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px 0px #00000040",
        maxHeight: height - 64 + "px",
        height: "fit-content",
        padding: "8px 0px",
        position: "fixed",
        left: "32px",
        top: "32px",
    };

    const logoStyle = {
        width: "96px",
        mixBlendMode: "darken",
    };

    const headerStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 20px 0px 8px",
        marginBottom: "8px",
    };

    const contentContainerStyle = {
        width: "100%",
        maxHeight: height - 64 - 54 - 48 + "px",
        overflow: "scroll",
    };

    const logOut = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    useEffect(() => {
        if (isAuthenticated && user) {
            if (token === null) {
                getAccessTokenSilently().then((tokenRep) => {
                    UsersServices.checkUser(user.sub, tokenRep).then(
                        (response) => {
                            if (response === "Not found") {
                                setShowCreateAccountPopup(true);
                            }
                        }
                    );
                    setToken(tokenRep);
                    console.log("token", tokenRep);
                });
            } else {
                UsersServices.checkUser(user.sub, token).then((response) => {
                    if (response === "Not found") {
                        setShowCreateAccountPopup(true);
                    }
                });
            }

            if (pathname === "/") {
                navigate("/collections");
                setFullScreen(false);
            } else if (pathname === "/collections/new") {
                setFullScreen(true);
            } else {
                setFullScreen(false);
            }
        }
    }, [
        isAuthenticated,
        pathname,
        navigate,
        getAccessTokenSilently,
        user,
        token,
        setToken,
    ]);

    return (
        <MapProvider>
            <div
                className={
                    (appState && !hideCollectionTab ? "visible " : "hidden ") +
                    "app-container"
                }
                style={containerStyle}
            >
                <FontAwesomeIcon
                    title={appState ? "Fermer" : "Ouvrir"}
                    display={appState ? "block" : "block"}
                    icon={
                        appState
                            ? "fa-solid fa-circle-chevron-left"
                            : "fa-solid fa-circle-chevron-right"
                    }
                    id="app-dismiss"
                    onClick={() => setAppState(!appState)}
                />
                <div style={headerStyle}>
                    <img
                        src={logoIdelia}
                        style={logoStyle}
                        alt="Logo de l'entreprise Idélia"
                    />
                    {isAuthenticated &&
                        (pathname === "/collections/new" ? (
                            <Button
                                extraStyles={{
                                    width: "fit-content",
                                    marginLeft: "8px",
                                }}
                                className="danger"
                                text="Fermer"
                                icon="fa-solid fa-xmark"
                                onClick={() => {
                                    setSearchCollection(null);
                                    navigate(-1);
                                }}
                            />
                        ) : (
                            <Button
                                text="Nouvelle collection"
                                icon="fa-solid fa-plus"
                                className="primary"
                                onClick={() => navigate("/collections/new")}
                            />
                        ))}
                </div>
                {isAuthenticated || isLoading ? (
                    <div
                        id="app-content-container"
                        style={{
                            ...contentContainerStyle,
                            height: fullScreen === true && "100vh",
                        }}
                    >
                        <Outlet />
                    </div>
                ) : (
                    <>
                        <div className="login-container">
                            <h3>Connectez-vous pour accéder à l'application</h3>
                            <button
                                id="login-button"
                                onClick={loginWithRedirect}
                            >
                                <FontAwesomeIcon
                                    style={{ marginRight: "8px" }}
                                    icon="fa-solid fa-lock"
                                />
                                Se connecter
                            </button>
                        </div>
                    </>
                )}
            </div>
            {isAuthenticated && !isLoading && user && (
                <>
                    <SearchBar mapRef={mapRef} />
                    <UserIcon image={user.picture} onClick={logOut} />
                    <NewAccountPopup
                        visible={showCreateAccountPopup}
                        user={user}
                        onAccountCreated={() =>
                            setShowCreateAccountPopup(false)
                        }
                    />
                </>
            )}
            <Mapbox mapRef={mapRef} />
        </MapProvider>
    );
}

export default AppContainer;
