function SearchItem({ text, key, onClick, feature }) {

    const handleClick = () => {
        onClick(feature)
    }

    return (
        <div id={key} className="search-item" onClick={handleClick}>
            <span>{text}</span>
        </div>
    )
}

export default SearchItem;