const URL_API = "https://app.idelia.org:3001";

const DVFServices = {

    getDVFByPlot: async (plot) => {
        const response = await fetch(`${URL_API}/api/dvf/plots/${plot}`);
        const json = await response.json();
        let dvf = []
        json.forEach(year => {
            year.forEach(transaction => {
                dvf.push(transaction);
            });
        });
        return dvf;
    },

    getDVFBySection: async (plotId) => {
        const response = await fetch(`${URL_API}/api/dvf/sections/${plotId}`);
        const json = await response.json();
        let dvf = []
        json.forEach(year => {
            year.forEach(transaction => {
                dvf.push(transaction);
            });
        });
        return dvf;
    }

}

export default DVFServices;