import errorIcon from '../assets/images/error.png'

function ErrorPage({ title, message }) {

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px'
    }

    return (
        <div style={containerStyle}>
            <img src={errorIcon} alt="Erreur" height={96} width={96} />
            <h2 style={{ margin: '8px' }}>{title}</h2>
            <p style={{ margin: '0px 0px 8px 0px' }}>{message}</p>
        </div>
    )
}

export default ErrorPage;