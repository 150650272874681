const UtilsServices = {

    truncateStringToFit: (text, maxWidth, font) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;

        let truncatedText = text;
        while (context.measureText(truncatedText).width > maxWidth) {
            truncatedText = truncatedText.slice(0, -1);
        }

        return (truncatedText !== text) ? truncatedText + '...' : truncatedText;
    },

    mysqlEscapeString: (str) => {
        // eslint-disable-next-line no-useless-escape
        return str.replaceAll("'", "\'")
    },

    sortCollection: (collection) => {
        return collection.sort((a, b) => {
            const numA = parseInt(a.prefixe, 10);
            const numB = parseInt(b.prefixe, 10);
            const wordA = a.section.toUpperCase();
            const wordB = b.section.toUpperCase();
            const numC = parseInt(a.numero, 10);
            const numD = parseInt(b.numero, 10);

            if (numA < numB) return -1;
            if (numA > numB) return 1;

            if (wordA < wordB) return -1;
            if (wordA > wordB) return 1;

            if (numC < numD) return -1;
            if (numC > numD) return 1;

            return 0;
        });
    },

    getDaysGroupedByDayOfWeek: (startDate, endDate) => {
        // Convert input dates to Date objects if they aren't already
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Initialize an array to hold counts for each day of the week (Sunday to Saturday)
        const dayOfWeekCounts = new Array(7).fill(0);

        // Loop over each day in the interval
        let currentDate = new Date(start);
        while (currentDate <= end) {
            // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
            const dayOfWeek = currentDate.getDay();

            // Increment the count for the current day of the week
            dayOfWeekCounts[dayOfWeek]++;

            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Map day indices to French day names
        const daysOfWeekFrench = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];

        // Construct the result object
        const result = {};
        for (let i = 0; i < 7; i++) {
            result[daysOfWeekFrench[i]] = dayOfWeekCounts[i];
        }

        return result;
    }

}

export default UtilsServices