import emptyIcon from '../assets/images/no-results.png'
import Button from './button';

function NoResults({ title, message, buttonText, buttonIcon, onClick, buttonClass }) {

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px'
    }

    return (
        <div style={containerStyle}>
            <img src={emptyIcon} alt="Pas de résultats" height={96} width={96} />
            <h2 style={{ margin: '8px' }}>{title}</h2>
            <p style={{ margin: '0px 0px 8px 0px' }}>{message}</p>
            {buttonText && onClick && (
                <Button extraStyles={{ marginTop: "10px" }} text={buttonText} onClick={onClick} icon={buttonIcon ? buttonIcon : null} className={buttonClass ? buttonClass : null} />
            )}
        </div>
    )
}

export default NoResults;