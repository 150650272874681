import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ElevationGraph = ({ elevationData, onElevationClose, distance }) => {

    const dataPoints = []
    for (let i = 0; i < elevationData.length; i++) {
        dataPoints.push({
            x: (i / elevationData.length) * distance,
            y: elevationData[i].z
        })
    }


    const options = {
        animationEnabled: true,
        theme: 'light2',
        title: {
            text: 'Altimétrie'
        },
        axisX: {
            title: 'Distance (m)'
        },
        axisY: {
            title: 'Elevation (m)'
        },
        data: [
            {
                type: 'splineArea',
                markerSize: 5,
                dataPoints,
                mousemove: (e) => {
                    hoverMarker(e.dataPointIndex)
                }
            }
        ],
    };

    const hoverMarker = (index) => {
        const markers = document.getElementsByClassName("elevation-marker");
        for (let i = 0; i < markers.length; i++) {
            markers[i].classList.remove("active");
        }

        const marker = document.getElementById("elevation-marker-" + index);
        marker.classList.add("active");
    }

    const handleMouseOver = (e) => {
        //console.log(e)
    }

    const graphStyle = {
        backgroundColor: 'white',
    }

    return (
        <div style={graphStyle} onMouseMove={handleMouseOver} >
            <CanvasJSChart options={options} />
        </div>
    );
};

export default ElevationGraph;