import '../style/user-icon.css'

function UserIcon({ onClick, image }) {

    return (
        // same but with image from url as background
        <div id="user-icon" onClick={onClick} style={{ backgroundImage: `url(${image})` }}>
        </div>
    )
}

export default UserIcon;