import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chartColors from '../../assets/chart-colors.json'

const FillRate = ({ width, clubIds, data, refreshing, onRefresh }) => {

    return (
        <div className="padel-component">
            <div className="padel-component-header">
                <h2>Remplissage (%)</h2>
                <FontAwesomeIcon className="clickable-icon" icon="fa-solid fa-arrows-rotate" onClick={onRefresh} spin={refreshing} />
            </div>
            {refreshing ? <div className="loading">Chargement...</div>
                : <BarChart data={data} width={width} height={300} >
                    <Tooltip />
                    <XAxis dataKey={"name"} />
                    <YAxis domain={[0, 100]} />
                    <Bar fill={chartColors[0].color} dataKey={"Remplissage"} />
                </BarChart>
            }
        </div>
    )

}

export default FillRate