import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../components/button';
import Loader from '../components/loader';
import ZonePLU from '../components/zone-plu';
import CollectionServices from '../services/collections';
import "../style/parcelle.css"
import { useGlobalState } from './app-container';
import * as turf from '@turf/turf';
import CadastreServices from '../services/cadastre';

function Parcelle() {

    let navigate = useNavigate();
    const { collectionId, plotId } = useParams();
    const { getAccessTokenSilently } = useAuth0();

    const [collection, setCollection] = useGlobalState('openedCollection');
    const [token, setToken] = useGlobalState('token');
    const [parcelle, setParcelle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMemoSaving, setIsMemoSaving] = useState(false);

    useEffect(() => {
        if (token)
            CollectionServices.getCollectionByUUID(collectionId, token).then((response) => {
                if (response.status === 200) {
                    setCollection(response.collection);
                    setParcelle(response.collection.plots.find((plot) => plot.id === plotId));
                    setLoading(false);
                }
            })
        else {
            getAccessTokenSilently().then((tokenRep) => {
                setToken(tokenRep);
                CollectionServices.getCollectionByUUID(collectionId, tokenRep).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                        setParcelle(response.collection.plots.find((plot) => plot.id === plotId));
                        setLoading(false);
                    }
                })
            })
        }
    }, [collectionId, plotId, token, getAccessTokenSilently, setToken, setCollection])

    const updateMemo = () => {
        setIsMemoSaving(true);
        CollectionServices.updateCollection({
            ...collection, plots: collection.plots.map((plot) => {
                if (plot.id === plotId) {
                    plot.memo = parcelle.memo;
                }
                return plot;
            })
        }, token).then((response) => {
            if (response.status === 200) {
                CollectionServices.getCollectionByUUID(collectionId, token).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                        setParcelle(response.collection.plots.find((plot) => plot.id === plotId));
                        setIsMemoSaving(false);
                    }
                })
            }
        })
    }

    const superficieStyle = {
        backgroundColor: 'rgb(var(--primary))',
        color: 'white',
        padding: 8,
        borderRadius: 8,
        fontWeight: 'bold',
        width: "fit-content",
        marginTop: 8,
        marginRight: 8
    }


    const handleGeoportailClick = () => {
        let centerOfPlot = turf.centerOfMass(parcelle.plot_geometry);
        let lngLat = { lng: centerOfPlot.geometry.coordinates[0], lat: centerOfPlot.geometry.coordinates[1] }
        CadastreServices.openGeoportailUrbanismeMap(lngLat)
    }

    return (
        <>
            <Button text="Retour" icon="fa-solid fa-chevron-left" onClick={() => { navigate(-1) }} className="primary" extraStyles={{ marginLeft: 8, marginBottom: 8 }} />
            {
                loading ? <Loader />
                    : <>
                        <h1>Parcelle {(parcelle.prefixe === "000" ? "" : parcelle.prefixe) + " " + parcelle.section + " " + parcelle.numero}</h1>
                        <div className="parcelle-container">
                            <div style={{ marginBottom: '16px' }}>
                                <span style={superficieStyle}>{parcelle.contenance} m²</span>
                                <span>
                                    {parcelle.commune}
                                    <span style={{ color: 'rgb(var(--black-7))' }}> ({parcelle.code_insee})</span>
                                </span>
                            </div>
                            <Button text="Voir sur le Géoportail" onClick={handleGeoportailClick} />
                            <div className="parcelles-zones">
                                <h2>Zones</h2>
                                {parcelle.zones.length > 0
                                    ? parcelle.zones.sort((a, b) => {
                                        if (a.typezone < b.typezone) { return -1; }
                                        if (a.typezone > b.typezone) { return 1; }
                                        return 0;
                                    }).map((zone) => {
                                        return <ZonePLU zone={zone} />
                                    })
                                    : <p>Aucune zone</p>
                                }
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h2>Mémo</h2>
                                <textarea className="parcelle-memo" value={parcelle.memo} onChange={(e) => setParcelle({ ...parcelle, memo: e.target.value })} />
                                <Button isLoading={isMemoSaving} extraStyles={{ alignSelf: 'flex-end', marginRight: 0, marginTop: 8 }} text="Enregistrer" icon="fa-solid fa-save" onClick={updateMemo} className="success" />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Parcelle;