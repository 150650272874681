import { useNavigate } from "react-router-dom";
import "../style/context-menu.css";
import CadastreServices from "../services/cadastre";

function ContextMenu({ parcelle, pos, onClose, lngLat, address }) {
    let navigate = useNavigate();

    const handleGeoportailClick = () => {
        onClose();
        CadastreServices.openGeoportailUrbanismeMap(lngLat);
    };

    const handleRechercheClick = () => {
        onClose();
        navigate("/collections/new", { state: parcelle });
    };

    const handleParcelleClick = () => {
        onClose();
        navigate("/parcelle/" + parcelle.id);
    };

    const handleGMapsClick = () => {
        onClose();
        let url =
            "http://maps.google.com/maps?q=" +
            lngLat.lat +
            "," +
            lngLat.lng +
            "&t=k";
        window.open(url, "_blank").focus();
    };

    const handleSmappenClick = () => {
        onClose();
        let url =
            `https://www.smappen.fr/app/?address=` +
            address +
            `&lat=` +
            lngLat.lat +
            `&lng=` +
            lngLat.lng;
        window.open(url, "_blank").focus();
    };

    return (
        <div className="context-menu" style={{ left: pos.x, top: pos.y }}>
            <div className="context-menu_title">
                Parcelle Parcelle{" "}
                {(parcelle.prefixe === "000" ? "" : parcelle.prefixe) +
                    " " +
                    parcelle.section +
                    " " +
                    parcelle.numero}{" "}
            </div>
            <div onClick={handleRechercheClick} className="context-menu_item">
                Démarrer une recherche à partir de la parcelle
            </div>
            <div onClick={handleParcelleClick} className="context-menu_item">
                Fiche détaillée de la parcelle
            </div>
            <div onClick={handleGeoportailClick} className="context-menu_item">
                Voir sur le Géoportail de l'urbanisme
            </div>
            <div
                onClick={() => {
                    window.open(
                        "https://www.cadastre.gouv.fr/scpc/accueil.do",
                        "_blank"
                    );
                }}
                className="context-menu_item"
            >
                Ouvrir cadastre.gouv.fr
            </div>
            <div onClick={handleGMapsClick} className="context-menu_item">
                Voir sur Google Maps
            </div>
            <div onClick={handleSmappenClick} className="context-menu_item">
                Voir sur Smappen
            </div>
        </div>
    );
}

export default ContextMenu;
