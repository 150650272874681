import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AllowedUser from "../components/allowed-user";
import Button from "../components/button";
import IconButton from "../components/icon-button";
import CadastreServices from "../services/cadastre";
import CollectionServices from "../services/collections";
import UsersServices from "../services/users";
import { useGlobalState } from "./app-container";

function CollectionSettings() {
    let navigate = useNavigate();

    const { getAccessTokenSilently } = useAuth0();

    const [collection, setCollection] = useGlobalState('openedCollection');
    const [token, setToken] = useGlobalState('token');
    const [users, setUsers] = useState([]);
    const [isExporting, setIsExporting] = useState(false);
    const [author, setAuthor] = useState(null);

    useEffect(() => {
        if (token) {
            UsersServices.getAllUsers(token).then((response) => {
                setUsers(response);
            })
        } else {
            getAccessTokenSilently().then((tokenRep) => {
                setToken(tokenRep);
                UsersServices.getAllUsers(tokenRep).then((response) => {
                    setUsers(response);
                })
            })
        }
    }, [token, getAccessTokenSilently, setToken])

    const toggleFav = () => {
        CollectionServices.updateCollectionFav(collection.uuid, collection.fav === 1 ? 0 : 1, token).then((response) => {
            if (response.status === 200) {
                CollectionServices.getCollectionByUUID(collection.uuid, token).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                    }
                })
            }
        })
    }

    const deleteAllowedUser = (user) => {
        CollectionServices.updateCollectionUsers(collection.uuid, collection.sharedWith.filter((u) => u.uuid !== user.uuid), token).then((response) => {
            if (response.status === 200) {
                CollectionServices.getCollectionByUUID(collection.uuid, token).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                    }
                })
            }
        })
    }

    const handleAccessChange = (user, access) => {
        CollectionServices.updateCollectionUsers(collection.uuid, collection.sharedWith.map((u) => u.uuid === user.uuid ? { ...u, can_read: 1, can_write: access === "write" ? 1 : 0 } : u), token).then((response) => {
            if (response.status === 200) {
                CollectionServices.getCollectionByUUID(collection.uuid, token).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                    }
                })
            }
        })
    }

    const handleDeleteCollection = () => {
        // opens an alert modal to confirm deletion
        // if confirmed, delete the collection
        // if not, do nothing
        if (window.confirm("Voulez-vous vraiment supprimer cette collection ?")) {
            CollectionServices.deleteCollection(collection.uuid, token).then((response) => {
                if (response.status === 200) {
                    setCollection(null)
                    navigate(-2);
                }
            })
        }
    }

    const handleUserAdd = (e) => {
        if (e.target.value !== "0") {
            CollectionServices.updateCollectionUsers(collection.uuid, [...collection.sharedWith, { ...users.find((u) => u.uuid === e.target.value), can_read: 1, can_write: 1, fav: 0, last_edited: new Date().toISOString().slice(0, 19).replace('T', ' ') }], token).then((response) => {
                if (response.status === 200) {
                    CollectionServices.getCollectionByUUID(collection.uuid, token).then((response) => {
                        if (response.status === 200) {
                            setCollection(response.collection);
                        }
                    })
                }
            })
        }
    }

    const exportCollection = async () => {
        console.log("exporting collection (", collection.plots.length, " plots)");
        if (collection.plots.length > 0) {
            setIsExporting(true);
            let csvContent = "data:text/csv;charset=utf-8,";
            let finished = 0;

            for (const plot of collection.plots) {
                console.log("Plot " + plot.numero + " : démarrage...");
                let zones = plot.zones.map((zone) => zone.zone).sort().join("/");
                let center = [(plot.bbox[0] + plot.bbox[2]) / 2, (plot.bbox[1] + plot.bbox[3]) / 2];

                let response = -1;

                while (response === -1) {
                    console.log("Plot " + plot.numero + " : en cours...");
                    response = await CadastreServices.getDataByCoord(center[1], center[0]);
                    if (response === "no data") {
                        finished++;
                        console.log("Plot " + plot.numero + " : pas de données");
                        csvContent += ";" + plot.commune + ";;;" + (plot.prefixe === "000" ? "" : plot.prefixe) + ";" + plot.section + ";" + plot.numero + ";" + (plot.prefixe === "000" ? "" : plot.prefixe) + " " + plot.section + " " + plot.numero + ";" + plot.contenance + ";" + zones + "\n";
                    } else if (response !== -1) {
                        finished++;
                        console.log("Plot " + plot.numero + " : OK");
                        csvContent += ";" + response.city + ";" + response.postcode + ";" + response.name + ";" + (plot.prefixe === "000" ? "" : plot.prefixe) + ";" + plot.section + ";" + plot.numero + ";" + (plot.prefixe === "000" ? "" : plot.prefixe + " ") + plot.section + " " + plot.numero + ";" + plot.contenance + ";" + zones + "\n";
                    }
                }

                console.log("Progress : " + (finished / collection.plots.length * 100).toFixed(2) + "%");

                if (finished === collection.plots.length) {
                    console.log("exporting collection (", collection.plots.length, " plots) : done !");
                    var csvLink = document.createElement("a");
                    csvLink.setAttribute("href", encodeURI(csvContent));
                    csvLink.setAttribute("download", collection.name.replaceAll(" ", "") + ".csv");
                    csvLink.click();
                    setIsExporting(false);
                };
            }
        }
    }

    if (author === null && collection !== null && users.length > 0) {
        setAuthor(users.find((u) => u.uuid === collection.author_uuid));
        console.log(author);
    }

    return (
        collection && (
            <>
                <Button text="Retour" icon="fa-solid fa-chevron-left" onClick={() => { navigate(-1) }} className="primary" extraStyles={{ marginLeft: 8, marginBottom: 8 }} />
                <div style={{ justifyContent: "flex-start" }} className='collections-top-bar'>
                    <IconButton icon={collection.fav === 0 ? "fa-regular fa-bookmark" : "fa-solid fa-bookmark"} onClick={toggleFav} />
                    <h2 style={{ marginLeft: 8 }}>{collection.name}</h2>
                </div>
                {author != null && (<span style={{ marginLeft: 8, fontFamily: "Source Sans Pro Italic", display: "block", marginBottom: 8 }}>Collection créée par <strong>{author.first_name + " " + author.name}</strong></span>)}
                <div className="collection-desc">
                    <p>{collection.description.length > 0 ? collection.description : "Pas de description"}</p>
                </div>
                <Button icon="fa-solid fa-download" text="Exporter la collection" className="primary" onClick={exportCollection} isLoading={isExporting} extraStyles={{ marginLeft: 8 }} />
                <div className="allowed-users">
                    <h3>Utilisateurs autorisés</h3>
                    {collection.sharedWith.length > 0
                        ? collection.sharedWith.map((user) => {
                            return <AllowedUser key={user.uuid} user={user} onDelete={deleteAllowedUser} onAccessChange={handleAccessChange} />
                        })
                        : <p>Aucun utilisateur autorisé.</p>
                    }
                    <select name="add-allow-user" id="" onChange={handleUserAdd} >
                        <option value="0">Ajouter un utilisateur</option>
                        {users.filter((u) => !collection.sharedWith.map((u) => u.uuid).includes(u.uuid)).map((user) => {
                            return <option key={user.uuid} value={user.uuid}>{user.name + " " + user.first_name}</option>
                        })}
                    </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "16px" }}>
                    <Button text="Supprimer la collection" icon="fa-solid fa-trash" onClick={handleDeleteCollection} className="danger" />
                </div>
            </>
        )
    )
}

export default CollectionSettings;