import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import '../style/style.css'
import '../style/fonts.css'

import AppContainer from "./app-container";
import Collection from "./collection";
import Collections from "./collections";
import Parcelle from "./parcelle";
import NewCollection from "./new-collection";
import CollectionSettings from "./collection-settings";
import ParcelleDetails from "./parcelle-details";
import Padel from "./padel";

function Home() {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AppContainer />}>
                    <Route path='/collections' index element={<Collections />} />
                    <Route path='/collections/new' element={<NewCollection />} />
                    <Route path='/collections/:collectionId' element={<Collection />} />
                    <Route path='/collections/:collectionId/:plotId' element={<Parcelle />} />
                    <Route path='/collections/:collectionId/settings' element={<CollectionSettings />} />
                    <Route path='/parcelle/:plotId' element={<ParcelleDetails />} />
                    <Route path='*' element={<Collections />} />
                </Route>
                <Route path="/padel" element={<Padel />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Home;