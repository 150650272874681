import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SearchTabs({ tab, onTabChange }) {

    const [activeTab, setActiveTab] = useState(tab ? tab : 'search-tab-address')

    function onClick(active) {
        setActiveTab(active)
        onTabChange(active)
    }

    return (
        <ul className="search-tab-container">
            <li id='search-tab-address' className={activeTab === 'search-tab-address' ? 'active' : ''} onClick={() => { onClick('search-tab-address') }} >
                <FontAwesomeIcon icon="fa-solid fa-location-arrow" />
                <span>Adresse</span>
            </li>
            <li id='search-tab-plot' className={activeTab === 'search-tab-plot' ? 'active' : ''} onClick={() => { onClick('search-tab-plot') }}>
                <FontAwesomeIcon icon="fa-solid fa-house" />
                <span>Parcelle</span>
            </li>
        </ul>
    )
}

export default SearchTabs;