import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PlotListItem from '../components/plot-list-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollectionServices from '../services/collections';
import { useAuth0 } from '@auth0/auth0-react';
import NoResults from '../components/no-results';
import Loader from '../components/loader';
import IconButton from '../components/icon-button';
import { useGlobalState } from './app-container';
import { useMap } from 'react-map-gl';
import Button from '../components/button';
import UtilsServices from '../services/utils';
import CadastreServices from '../services/cadastre';

let token = null;

function Collection() {

    let navigate = useNavigate();
    let { collectionId } = useParams()
    const { getAccessTokenSilently } = useAuth0();

    const { map } = useMap();

    const [collection, setCollection] = useGlobalState('openedCollection');
    const [isExporting, setIsExporting] = useState(false);

    useEffect(() => {
        getAccessTokenSilently().then((tokenRep) => {
            token = tokenRep;
            CollectionServices.getCollectionByUUID(collectionId, tokenRep).then((response) => {
                if (response.status === 200) {
                    setCollection(response.collection);

                }
            })
        })
    }, [collectionId, getAccessTokenSilently, setCollection]);

    const exportCollection = async () => {
        console.log("exporting collection (", collection.plots.length, " plots)");
        if (collection.plots.length > 0) {
            setIsExporting(true);
            let csvContent = "data:text/csv;charset=utf-8,";
            let finished = 0;

            for (const plot of collection.plots) {
                console.log("Plot " + plot.numero + " : démarrage...");
                let zones = plot.zones.map((zone) => zone.zone).sort().join("/");
                let center = [(plot.bbox[0] + plot.bbox[2]) / 2, (plot.bbox[1] + plot.bbox[3]) / 2];

                let response = -1;

                while (response === -1) {
                    console.log("Plot " + plot.numero + " : en cours...");
                    response = await CadastreServices.getDataByCoord(center[1], center[0]);
                    if (response === "no data") {
                        finished++;
                        console.log("Plot " + plot.numero + " : pas de données");
                        csvContent += ";" + plot.commune.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ";;;" + (plot.prefixe === "000" ? "" : plot.prefixe) + ";" + plot.section + ";" + plot.numero + ";" + (plot.prefixe === "000" ? "" : plot.prefixe) + " " + plot.section + " " + plot.numero + ";" + plot.contenance + ";" + zones + "\n";
                    } else if (response !== -1) {
                        finished++;
                        console.log("Plot " + plot.numero + " : OK");
                        csvContent += ";" + response.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ";" + response.postcode + ";" + response.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ";" + (plot.prefixe === "000" ? "" : plot.prefixe) + ";" + plot.section + ";" + plot.numero + ";" + (plot.prefixe === "000" ? "" : plot.prefixe + " ") + plot.section + " " + plot.numero + ";" + plot.contenance + ";" + zones + "\n";
                    }
                }

                console.log("Progress : " + (finished / collection.plots.length * 100).toFixed(2) + "%");

                if (finished === collection.plots.length) {
                    console.log("exporting collection (", collection.plots.length, " plots) : done !");
                    var csvLink = document.createElement("a");
                    csvLink.setAttribute("href", encodeURI(csvContent));
                    csvLink.setAttribute("download", collection.name.replaceAll(" ", "") + ".csv");
                    csvLink.click();
                    setIsExporting(false);
                };
            }
        }
    }

    const updateDescription = (e) => {
        CollectionServices.updateCollectionDescription(collection.uuid, e.target.value, token).then((response) => {
            if (response.status === 200) {
                CollectionServices.getCollectionByUUID(collectionId, token).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                    }
                })
            }
        })
    }

    const updateName = (e) => {
        CollectionServices.updateCollectionName(collection.uuid, e.target.value, token).then((response) => {
            if (response.status === 200) {
                CollectionServices.getCollectionByUUID(collectionId, token).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                    }
                })
            }
        })
    }

    const toggleFav = () => {
        CollectionServices.updateCollectionFav(collection.uuid, collection.fav === 1 ? 0 : 1, token).then((response) => {
            if (response.status === 200) {
                CollectionServices.getCollectionByUUID(collectionId, token).then((response) => {
                    if (response.status === 200) {
                        setCollection(response.collection);
                    }
                })
            }
        })
    }

    const handleFavClick = (plotUUID, fav) => {
        if (token === null || token === undefined) {
            getAccessTokenSilently().then((tokenRep) => {
                token = tokenRep;
                updateFav(plotUUID, fav, tokenRep);
            })
        } else {
            updateFav(plotUUID, fav, token);
        }
    }

    const copyPlotsNumber = () => {
        let plotsNumber = "";
        collection.plots.forEach((plot) => {
            plotsNumber += plot.section + " " + plot.numero + "\n";
        })
        navigator.clipboard.writeText(plotsNumber);
    }

    const updateFav = (plotUUID, fav, token) => {
        setCollection({
            ...collection, plots: collection.plots.map((plot) => {
                if (plot.id === plotUUID) {
                    plot.fav = fav;
                }
                return plot;
            })
        })
        CollectionServices.updateCollection({
            ...collection, plots: collection.plots.map((plot) => {
                if (plot.id === plotUUID) {
                    plot.fav = fav;
                }
                return plot;
            })
        }, token).then(() => {
            CollectionServices.getCollectionByUUID(collectionId, token).then((response) => {
                if (response.status === 200) {
                    setCollection(response.collection);
                }
            })
        })
    }

    const handleBboxClick = (bbox) => {
        map.fitBounds(bbox, { padding: 100 });
    }

    return (
        collection && collection.plots
            ?
            <>
                <Button text="Retour" icon="fa-solid fa-chevron-left" onClick={() => { setCollection(null); navigate(-1) }} className="primary" extraStyles={{ marginLeft: 8, marginBottom: 8 }} />
                <div className='collections-top-bar'>
                    <IconButton icon={collection.fav === 0 ? "fa-regular fa-bookmark" : "fa-solid fa-bookmark"} onClick={toggleFav} />
                    {collection.can_write
                        ? <>
                            <input value={collection.name} onChange={(e) => setCollection({ ...collection, name: e.target.value })} onBlur={updateName} />
                            <FontAwesomeIcon style={{ height: '20px' }} icon='fa-solid fa-gear' onClick={() => { navigate('/collections/' + collectionId + "/settings", { state: collection }) }} id="collection-settings-icon" />
                        </>
                        : <h2 style={{ marginLeft: 8, flex: 1 }}>{collection.name}</h2>
                    }
                </div>
                <div className="collection-desc">
                    {collection.can_write
                        ? <textarea value={collection.description} placeholder="Saisir une description..." onChange={(e) => setCollection({ ...collection, description: e.target.value })} onBlur={updateDescription} />
                        : <p>{collection.description.length > 0 ? collection.description : "Pas de description"}</p>
                    }
                </div>
                <Button icon="fa-solid fa-download" text="Exporter la collection" className="primary" onClick={exportCollection} isLoading={isExporting} extraStyles={{ marginLeft: 8, marginTop: 8 }} />
                <Button icon="fa-solid fa-mouse" text="Copier numero des parcelles" className="primary" extraStyles={{ marginLeft: 8, marginTop: 8 }} onClick={copyPlotsNumber} />
                <ul style={{ padding: 0, margin: 0 }}>
                    {collection && collection.plots.length > 0
                        ? <>
                            {collection.plots.filter(item => item.fav === 1).length > 0 && (
                                <>
                                    <h2 style={{ marginLeft: "8px" }}>Favoris</h2>
                                    <ul style={{ padding: 0, margin: 0 }}>
                                        {UtilsServices.sortCollection(collection.plots.filter((parcelle) => { return parcelle.fav === 1 })).map(function (item) {
                                            return <PlotListItem onBboxClick={handleBboxClick} onFavClick={handleFavClick} key={item.insee + " " + item.prefixe + " " + item.section + " " + item.numero} plot={item} onClick={() => { navigate('/collections/' + collectionId + "/" + item.id, { state: item }) }} />
                                        })}
                                    </ul>
                                    <hr style={{ border: "none", borderBottom: "1px solid rgb(var(--black-2))", margin: "16px 8px 16px 8px" }} />
                                </>
                            )}
                            {UtilsServices.sortCollection(collection.plots).map(function (item) {
                                return <PlotListItem onBboxClick={handleBboxClick} onFavClick={handleFavClick} key={item.insee + " " + item.prefixe + " " + item.section + " " + item.numero} plot={item} onClick={() => { navigate('/collections/' + collectionId + "/" + item.id, { state: item }) }} />
                            })}
                        </>
                        : <NoResults title="Aucune parcelle" message="Celle collection est vide. Ajouter des parcelles à la collection en les sélectionnant sur la carte." />
                    }
                </ul>
            </>
            : <Loader />
    )
}

export default Collection;