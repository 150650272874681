import Select from 'react-select'
import padelClubs from '../../assets/padel-clubs.json'

function PadelSelection({ selectedClubs, onClubChange, onValid, interval, onIntervalChange, availableTimeslots }) {

    const clubs = padelClubs.map(club => {
        return { value: club.id, label: club.name }
    })

    const handleIntervalChange = (e) => {
        switch (e.target.id) {
            case 'interval-start':
                onIntervalChange(e.target.value, interval.end)
                break
            case 'interval-end':
                let date = new Date(e.target.value)
                date.setDate(date.getDate() + 1)
                onIntervalChange(interval.start, e.target.value)
                break
            default:
                break
        }
    }

    return (
        <div className="padel-selection">
            <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', maxWidth: '60%' }}>
                <button style={{ height: 38, width: 38, marginRight: 8, cursor: 'pointer', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 4 }} onClick={onValid}>OK</button>
                <Select className='padel-club-select' value={selectedClubs} placeholder="Choisir un club" isMulti options={clubs} isClearable onChange={(e) => onClubChange(e)} />
                {availableTimeslots.totalTimeslots && <span>{availableTimeslots.totalTimeslots} créneaux horaires sur {availableTimeslots.dayCount} jour(s)</span>}
            </div>
            <div>
                <label htmlFor='interval-start'>Entre</label>
                <input value={interval.start} className='padel-date-select' id='interval-start' type='date' onChange={handleIntervalChange} />
                <label htmlFor='interval-end'>et</label>
                <input value={interval.end} className='padel-date-select' id='interval-end' type='date' onChange={handleIntervalChange} />
            </div>
        </div>
    )

}

export default PadelSelection