import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Button({ text, onClick, icon, disabled, id, extraStyles, isLoading, className }) {

    const styles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 8px",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "bold",
        margin: "0px 8px 0px 0px",
        width: "fit-content",
        transitionDuration: "0.1s",
    }

    const iconStyle = {
        height: "16px",
        margin: text ? "0px 8px 0px 4px" : "0px 4px"
    }

    return (
        <div style={{ ...styles, ...extraStyles }} className={((disabled || isLoading) ? "disabled " : "") + "custom-btn " + className} onClick={onClick} id={id && id}>
            {(isLoading || icon) && (
                <FontAwesomeIcon icon={isLoading ? "fa-solid fa-circle-notch" : icon} style={iconStyle} spin={isLoading} />
            )}
            {text}
        </div>
    )
}

export default Button;