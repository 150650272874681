const URL_API = "https://app.idelia.org:3001";
//const URL_API = "http://localhost:3001";


const UsersServices = {
    getUserInfos: async (userUUID, token) => {
        const response = await fetch(URL_API + "/api/users/" + userUUID, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        if (response.status !== 200) {
            return response;
        }
        return await response.json();
    },

    getAllUsers: async (token) => {
        const response = await fetch(URL_API + "/api/users", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        });
        if (response.status !== 200) {
            return response;
        }
        return await response.json();
    },

    checkUser: async (sub, token) => {
        const response = await fetch(URL_API + "/api/users/check", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify({
                sub: sub,
            })
        });
        if (response.status !== 200) {
            return response;
        }
        return await response.json();
    },

    createAccount: async (sub, email, name, firstName, token) => {
        const response = await fetch(URL_API + "/api/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify({
                sub: sub,
                email: email,
                name: name,
                first_name: firstName
            })
        });

        if (response.status !== 200) {
            return response;
        }
        return await response.json();
    },

}

export default UsersServices;