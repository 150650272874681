import { useEffect, useRef, useState } from "react";
import InputText from "./input-text";
import SearchServices from "../services/search";
import SearchTabs from "./search-tabs";
import AsyncSelect from 'react-select/async'
import '../style/search.css';
import SearchItem from "./search-item";
import Loader from "./loader";
import NoResults from "./no-results";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CadastreServices from "../services/cadastre";
import ErrorPage from "./error-page";
import mapboxgl from "mapbox-gl";
import Button from "./button";
import * as turf from '@turf/turf'

function SearchBar({ mapRef }) {

    const ref = useRef(null);

    const [search, setSearch] = useState('');
    const [prefixe, setPrefixe] = useState('');
    const [section, setSection] = useState('');
    const [numero, setNumero] = useState('');
    const [commune, setCommune] = useState('');

    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchTabsOpen, setSearchTabsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('search-tab-address');

    const [searchByAdressData, setSearchByAdressData] = useState([]);
    const [searchByPlotData, setSearchByPlotData] = useState([]);
    //const [searchThroughCollectionsData, setSearchThroughCollectionsData] = useState([]);

    const [searched, setSearched] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        function handleClickOutSide(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsSearchOpen(false)
                setSearchTabsOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutSide);

        return () => {
            document.removeEventListener("mousedown", handleClickOutSide);
        }
    }, [ref]);

    function onEnter(value) {
        setSearch(value)
        if (value.length > 2 || activeTab === 'search-tab-plot') {
            if (activeTab === 'search-tab-address') {
                setIsSearchOpen(true)
                setSearched(true)
                SearchServices.searchByAddress(value).then((data) => {
                    setSearchByAdressData(data)
                }).catch((err) => {
                    console.log(err)
                })
            } else if (activeTab === 'search-tab-plot') {
                if (numero.length > 0 && section.length > 0) {
                    setIsSearchOpen(true)
                    setSearched(true)
                    setLoading(true)
                    CadastreServices.getPlotDetails(numero, (section.length === 1 ? "0" : "") + section.toUpperCase(), prefixe.length > 0 ? prefixe : '', null, commune.length === 5 ? commune : '').then((plotData) => {
                        setSearchByPlotData(plotData)
                        setLoading(false)
                    })
                }
            }
        } else {
            setIsSearchOpen(false)
        }
    }

    const containerStyle = {
        position: 'fixed',
        top: '32px',
        left: '576px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    }

    const flyOnClick = (feature) => {
        mapRef.current.getMap()._markers.forEach((marker) => {
            marker.remove()
        })

        let marker = new mapboxgl.Marker().setLngLat([feature.geometry.coordinates[0], feature.geometry.coordinates[1]])
        marker.getElement().classList.add("search-marker");
        marker.addTo(mapRef.current.getMap())
        setTimeout(() => {
            marker.remove()
        }, 30000);

        mapRef.current.getMap().flyTo({ center: [feature.geometry.coordinates[0], feature.geometry.coordinates[1]], zoom: 20 });
        setIsSearchOpen(false)
    }

    const flyToPlot = (plot) => {
        mapRef.current.getMap()._markers.forEach((marker) => {
            marker.remove()
        })

        let plotCenter = turf.center(plot).geometry.coordinates

        let marker = new mapboxgl.Marker().setLngLat([plotCenter[0], plotCenter[1]])
        marker.getElement().classList.add("search-marker");
        marker.addTo(mapRef.current.getMap())
        setTimeout(() => {
            marker.remove()
        }, 30000);

        mapRef.current.getMap().fitBounds(plot.properties.bbox, { padding: 128, duration: 1000 });
        setIsSearchOpen(false)
        setSearchTabsOpen(false)
    }

    const onInputClick = () => {
        if (searched > 0) {
            setIsSearchOpen(true)
        }
        setSearchTabsOpen(true);
    }

    const onPlotSearchInputChange = (e) => {
        switch (e.target.name) {
            case 'prefixe':
                if (e.target.value.length > 0) {
                    if (e.target.value[e.target.value.length - 1].match(/^[0-9]*$/)) {
                        setPrefixe(e.target.value)
                        if (e.target.value.length === 3) document.getElementById('section-search-input').focus()
                    } else {
                        e.target.value = e.target.value.slice(0, -1);
                    }
                } else {
                    setPrefixe('')
                }
                break;
            case 'section':
                if (e.target.value.length > 0) {
                    if (e.target.value[e.target.value.length - 1].match(/^[a-zA-Z0-9]*$/)) {
                        setSection(e.target.value)
                        if (e.target.value.length === 2) document.getElementById('numero-search-input').focus()
                    } else {
                        e.target.value = e.target.value.slice(0, -1);
                    }
                } else {
                    setSection('')
                }
                break;
            case 'numero':
                if (e.target.value.length > 0) {
                    if (e.target.value[e.target.value.length - 1].match(/^[0-9]*$/)) {
                        setNumero(e.target.value)
                        if (e.target.value.length === 4) document.getElementById('departement-search-input').focus()
                    } else {
                        e.target.value = e.target.value.slice(0, -1);
                    }
                } else {
                    setNumero('')
                }
                break;
            default:
                break;
        }
    }

    const handlePlotSearchEnter = (e) => {
        if (e.key === 'Enter') {
            onEnter('')
        } else if (e.key === 'Backspace') {
            if (e.target.value.length === 0) {
                if (e.target.name === 'section') {
                    document.getElementById('prefixe-search-input').focus()
                } else if (e.target.name === 'numero') {
                    document.getElementById('section-search-input').focus()
                }
            }
        }
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        setSearched(false)
    }

    const onCitySearchInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            onEnter('')
        }
    }

    const onCitySearchInputChange = (option, type) => {
        if (type.action === 'select-option') {
            setCommune(option.value)
            let departement = option.value.substring(0, 2)
            if (departement === 97) departement = option.value.substring(0, 3)
            if (numero.length > 0 && section.length > 0) {
                setIsSearchOpen(true)
                setSearched(true)
                setSearchByPlotData([])
                setLoading(true)
                CadastreServices.getPlotDetails(numero, (section.length === 1 ? "0" : "") + section.toUpperCase(), prefixe.length > 0 ? prefixe : '', departement, option.value).then((plotData) => {
                    setSearchByPlotData(plotData)
                    setLoading(false)
                })
            }
        } else if (type.action === 'clear') {
            setCommune('')
        }
    }

    const loadOptions = (inputValue, callback) => {
        if (inputValue.length > 2) {
            CadastreServices.getCitiesByName(inputValue).then((data) => {
                let options = []
                data.forEach((city) => {
                    options.push({ value: city.code, label: city.nom + " (" + city.codesPostaux[0] + ")" })
                })
                callback(options)
            })
        }
    }

    return (
        <div ref={ref} style={containerStyle} className="search-bar-container">
            {activeTab === 'search-tab-plot'
                ? (
                    <div className="plot-search-container" style={{ borderRadius: isSearchOpen || searchTabsOpen ? '4px 4px 0px 0px' : '4px' }}>
                        <FontAwesomeIcon icon="fa-magnifying-glass" height='32px' style={{ marginLeft: '8px', marginRight: '8px' }} />
                        <div className="plot-search-form">
                            <div className="top">
                                <label htmlFor="prefixe" className="plot-search-label">Prefixe</label>
                                <input value={prefixe} onClick={onInputClick} onKeyDown={handlePlotSearchEnter} id="prefixe-search-input" onChange={onPlotSearchInputChange} type="text" name="prefixe" maxLength={3} className="plot-search-input" style={{ width: '40px' }} />
                                <label htmlFor="section" className="plot-search-label">Section</label>
                                <input value={section} onClick={onInputClick} onKeyDown={handlePlotSearchEnter} id="section-search-input" onChange={onPlotSearchInputChange} type="text" name="section" maxLength={2} className="plot-search-input" style={{ width: '28px' }} />
                                <label htmlFor="numero" className="plot-search-label">Numéro</label>
                                <input value={numero} onClick={onInputClick} onKeyDown={handlePlotSearchEnter} id="numero-search-input" onChange={onPlotSearchInputChange} type="text" name="numero" maxLength={4} className="plot-search-input" style={{ width: '40px' }} />
                                <Button disabled={numero.length === 0 || section.length < 1 || commune.length < 5} onClick={onEnter} extraStyles={{ marginRight: 0 }} icon="fa-solid fa-magnifying-glass" className="primary" />
                            </div>
                            <div className="bottom">
                                <label className="plot-search-label">Commune</label>
                                <AsyncSelect onKeyDown={onCitySearchInputKeyDown} onChange={onCitySearchInputChange} onFocus={onInputClick} loadOptions={loadOptions} defaultOptions id="plot-search-city-select" placeholder="Saisir une commune..." />
                            </div>
                        </div>
                    </div>
                )
                : activeTab === 'search-tab-address'
                    ? <InputText text={search} onClick={onInputClick} onChange={setSearch} clearButton className={isSearchOpen || searchTabsOpen ? 'opened ' : 'closed '} id="search-bar" width='512px' icon='fa-magnifying-glass' placeHolder="Rechercher par adresse..." onEnter={onEnter} />
                    : <InputText text={search} onClick={onInputClick} onChange={setSearch} clearButton className={isSearchOpen || searchTabsOpen ? 'opened ' : 'closed '} id="search-bar" width='512px' icon='fa-magnifying-glass' placeHolder="Rechercher par collection..." onEnter={onEnter} />
            }
            {searchTabsOpen && <SearchTabs tab={activeTab} onTabChange={handleTabChange} />}
            <div className={(isSearchOpen ? "opened " : "closed ") + "search-results-container"}>
                {activeTab === 'search-tab-address' && (
                    searchByAdressData.features
                        ? searchByAdressData.features.length > 0
                            ? searchByAdressData.features.map((feature) => {
                                return <SearchItem feature={feature} onClick={flyOnClick} key={feature.properties.id} text={feature.properties.name + ", " + feature.properties.postcode + ", " + feature.properties.city} />
                            })
                            : <NoResults title="Pas de résultats" message="Votre recherche n'a retourné aucun résultat." />
                        : searched ? <Loader /> : null
                )}

                {activeTab === 'search-tab-plot' && (
                    loading ? <Loader /> :
                        searchByPlotData !== "erreur"
                            ? searchByPlotData.features
                                ? searchByPlotData.features.length > 0
                                    ? searchByPlotData.features.map((feature) => {
                                        return <SearchItem feature={feature} onClick={flyToPlot} key={feature.properties.idu} text={(feature.properties.com_abs === "000" ? "" : feature.properties.com_abs) + " " + feature.properties.section + " " + feature.properties.numero + ", " + feature.properties.nom_com} />
                                    })
                                    : <NoResults title="Aucune parcelle" message="Votre recherche n'a retourné aucun résultat, veuillez vérifier les valeurs saisies." />
                                : searched ? <Loader /> : null
                            : <ErrorPage title="Erreur technique" message="Erreur lors de la récupération des parcelles, veuillez vérifier les valeurs saisies." />
                )}
            </div>
        </div>
    )
}

export default SearchBar;