import { useEffect, useState } from "react";
import PadelServices from "../../services/padel";

const ClubView = () => {

    const [clubs, setClubs] = useState([])
    const [courts, setCourts] = useState([])

    useEffect(() => {
        PadelServices.getPadelClubs().then(res => {
            if (res && res.length > 0) setClubs(res)
        })
        PadelServices.getPadelCourts().then(res => {
            console.log(res)
            if (res && res.length > 0) setCourts(res)
        })
    }, []);

    return (
        <>
            {clubs.map((club, index) => (
                <div key={index} className="padel-component">
                    <div className="padel-component-header" style={{ justifyContent: 'flex-start' }}>
                        <h1>{club.name}</h1>
                        <span style={{ color: 'rgba(0, 0, 0, 0.5)', marginTop: 4 }}>{club.cp}</span>
                    </div>

                    <p>
                        {courts.filter(court => court.club === club.id).length} terrains ({courts.filter(court => court.club === club.id && court.indoor === 1).length} intérieurs, {courts.filter(court => court.club === club.id && court.indoor === 0).length} extérieurs)
                        <br />
                        Visibilité : réservation possible {club.visibility} jours à l'avance
                    </p>
                    <a href={club.url}>Site de réservation</a>
                </div>
            ))}
        </>
    )

}

export default ClubView;