//import useWindowDimensions from "../services/window-dimensions";
import { Tab } from '@headlessui/react'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CollectionListItem from "../components/collection-list-item";
import NoResults from "../components/no-results";
import CollectionServices from "../services/collections";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/loader";
import { useGlobalState } from './app-container';

let token = null;

function Collections() {

    let navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    //const { height } = useWindowDimensions();

    const [activeIndex, setActiveIndex] = useGlobalState('activeTabIndex');
    //const [selectedFilter, setSelectedFilter] = useState(0);
    const [ownCollections, setOwnCollections] = useState(null);
    const [sharedCollections, setSharedCollections] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    /*
    const containerStyle = {
        width: '100%',
        height: 'fit-content',
        maxHeight: height - 64 - 54 - 48 + 'px',
        overflow: 'scroll'
    }

    const filterStyles = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 8px',
    }

    const filterOptions = [
        { id: 0, label: "Dernière ouverture par moi" },
        { id: 1, label: "Dernière modification" },
        { id: 2, label: "Nombre de parcelles" }
    ];
    */

    const tabs = [
        { id: 0, label: "Mes collections" },
        { id: 1, label: "Partagées avec moi" },
    ]

    useEffect(() => {
        setIsLoading(true);
        let ownLoading = true;
        let sharedLoading = true;

        getAccessTokenSilently().then((tokenRep) => {
            token = tokenRep;

            CollectionServices.getOwnCollections(token).then(data => {
                if (data.status === 200) {
                    if (data.collections !== ownCollections) setOwnCollections(data.collections);
                }
                ownLoading = false;
                if (!ownLoading && !sharedLoading) setIsLoading(false);
            })

            CollectionServices.getSharedCollections(token).then(data => {
                if (data.status === 200) {
                    if (data.collections !== sharedCollections) setSharedCollections(data.collections);
                }
                sharedLoading = false;
                if (!ownLoading && !sharedLoading) setIsLoading(false);
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onActiveChange = (index) => {
        setActiveIndex(index);

        getAccessTokenSilently().then((tokenRep) => {
            token = tokenRep;

            switch (index) {
                case 0:
                    // getOwnCollections();
                    CollectionServices.getOwnCollections(token).then(data => {
                        if (data.status === 200) {
                            if (data.collections !== ownCollections) setOwnCollections(data.collections);
                        }
                    })
                    break;
                case 1:
                    // getSharedCollections();
                    CollectionServices.getSharedCollections(token).then(data => {
                        if (data.status === 200) {
                            if (data.collections !== sharedCollections) setSharedCollections(data.collections);
                        }
                    })
                    break;
                default:
                    break;
            }
        });
    }

    const handleFavClick = (collectionUUID, fav) => {
        CollectionServices.updateCollectionFav(collectionUUID, fav, token).then(data => {
            if (data.status === 200) {
                updateCollections();
            } else {
                alert("Une erreur est survenue lors de la mise à jour de la collection")
            }
        })
    }

    const updateCollections = () => {
        getAccessTokenSilently().then((tokenRep) => {
            token = tokenRep;

            CollectionServices.getOwnCollections(tokenRep).then(data => {
                if (data.status === 200) {
                    setOwnCollections(data.collections);
                }
            })

            CollectionServices.getSharedCollections(tokenRep).then(data => {
                if (data.status === 200) {
                    if (data.collections !== sharedCollections) setSharedCollections(data.collections);
                }
            })
        });
    }

    return (
        <>
            <Tab.Group selectedIndex={activeIndex} onChange={onActiveChange} >
                <Tab.List className="collection-tabs">
                    <Tab className={activeIndex === 0 ? "active" : ""} >Mes collections</Tab>
                    <Tab className={activeIndex === 1 ? "active" : ""} >Partagées avec moi</Tab>
                </Tab.List>
                <Tab.Panels>
                    <div className='collections-top-bar'>
                        <h1 style={{ marginLeft: 8 }}>{tabs[activeIndex].label}</h1>

                        { /*
                        <div style={filterStyles}>
                            <FilterSelect options={filterOptions} selectedOption={selectedFilter} onChange={setSelectedFilter} />
                        </div>
                        */ }
                    </div>
                    <Tab.Panel>
                        {isLoading
                            ? <Loader />
                            : ownCollections && ownCollections.length > 0
                                ? (
                                    <>
                                        {ownCollections.filter(item => item.fav === 1).length > 0 && (
                                            <>
                                                <h2 style={{ marginLeft: "8px" }}>Favoris</h2>
                                                <ul style={{ padding: 0, margin: 0 }}>
                                                    {ownCollections.map(function (item) {
                                                        if (item.fav === 1) return <CollectionListItem key={item.uuid} onFavClick={handleFavClick} collection={item} onClick={() => { navigate('/collections/' + item.uuid) }} />
                                                        else return null;
                                                    })
                                                    }
                                                </ul>
                                                <hr style={{ border: "none", borderBottom: "1px solid rgb(var(--black-2))", margin: "16px 8px 16px 8px" }} />
                                            </>
                                        )}
                                        <ul style={{ padding: 0, margin: 0 }}>
                                            {ownCollections.sort((a, b) => { return new Date(b.last_edited).getTime() - new Date(a.last_edited).getTime() }).map(function (item) {
                                                return <CollectionListItem key={item.uuid} collection={item} onFavClick={handleFavClick} onClick={() => { navigate('/collections/' + item.uuid) }} />
                                            })
                                            }
                                        </ul>
                                    </>
                                )
                                : <NoResults title="Pas de collection" message="Aucune collection n'a été partagée avec vous." buttonText="Nouvelle collection" buttonClass="primary" buttonIcon="fa-solid fa-plus" onClick={() => { navigate("/collections/new") }} />
                        }
                    </Tab.Panel>
                    <Tab.Panel>
                        {sharedCollections && sharedCollections.length > 0
                            ? (
                                <>
                                    {sharedCollections.filter(item => item.fav === 1).length > 0 && (
                                        <>
                                            <h2 style={{ marginLeft: "8px" }}>Favoris</h2>
                                            <ul style={{ padding: 0, margin: 0 }}>
                                                {sharedCollections.map(function (item) {
                                                    if (item.fav === 1) return <CollectionListItem onFavClick={handleFavClick} key={item.uuid} collection={item} onClick={() => { navigate('/collections/' + item.uuid) }} />
                                                    else return null;
                                                })
                                                }
                                            </ul>
                                            <hr style={{ border: "none", borderBottom: "1px solid rgb(var(--black-2))", margin: "16px 8px 16px 8px" }} />
                                        </>
                                    )}
                                    <ul style={{ padding: 0, margin: 0 }}>
                                        {sharedCollections.map(function (item) {
                                            return <CollectionListItem key={item.uuid} collection={item} onFavClick={handleFavClick} onClick={() => { navigate('/collections/' + item.uuid) }} />
                                        })
                                        }
                                    </ul>
                                </>
                            )
                            : <NoResults title="Pas de collection" message="Aucune collection n'a été partagée avec vous." buttonText="Nouvelle collection" buttonClass="primary" buttonIcon="fa-solid fa-plus" onClick={() => { navigate("/collections/new") }} />
                        }
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </>
    )
}

export default Collections;