import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMap } from 'react-map-gl';

import plotIcon from '../assets/images/parcelle3.png'
import '../style/list-items.css'
import IconButton from './icon-button';

function PlotListItem({ plot, type, onClick, onFavClick, onBboxClick, onAcceptClick, accepted }) {

    const { map } = useMap();

    function capitalizeWords(str) {
        return str.toLowerCase().split(/[\s,'-]+/).map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }

    const handleFavClick = (e) => {
        e.stopPropagation();
        onFavClick(plot.id, plot.fav === 0 ? 1 : 0);
    }

    const handleBboxClick = (e) => {
        e.stopPropagation();
        onBboxClick(plot.bbox);
    }

    const handleAcceptClick = (e) => {
        e.stopPropagation();
        onAcceptClick({ ...plot, accepted: !accepted });
    }

    const handleGMapsClick = (e) => {
        e.stopPropagation();
        // get center from plot.bbox
        const center = [(plot.bbox[0] + plot.bbox[2]) / 2, (plot.bbox[1] + plot.bbox[3]) / 2];
        let url = "http://maps.google.com/maps?q=" + center[1] + "," + center[0] + "&t=k";
        window.open(url, '_blank').focus();
    }

    const onMouseEnter = (e) => {

        if (map.getLayer("currentCollectionParcellesFill") !== undefined) {
            map.queryRenderedFeatures({ layers: ["currentCollectionParcellesFill"] }).forEach((feature) => {
                if (feature.properties.id === plot.id) {
                    map.setFeatureState({ source: "collection", id: feature.id }, { hover: true });
                }
            });
        }

        if (map.getLayer("searchCollectionParcellesFill") !== undefined) {
            map.queryRenderedFeatures({ layers: ["searchCollectionParcellesFill"] }).forEach((feature) => {
                if (feature.properties.id === plot.id) {
                    map.setFeatureState({ source: "searchCollection", id: feature.id }, { hover: true });
                }
            });
        }

    }

    const onMouseLeave = (e) => {

        if (map.getLayer("currentCollectionParcellesFill") !== undefined) {
            map.queryRenderedFeatures({ layers: ["currentCollectionParcellesFill"] }).forEach((feature) => {
                if (feature.properties.id === plot.id) {
                    map.setFeatureState({ source: "collection", id: feature.id }, { hover: false });
                }
            });
        }

        if (map.getLayer("searchCollectionParcellesFill") !== undefined) {

            map.queryRenderedFeatures({ layers: ["searchCollectionParcellesFill"] }).forEach((feature) => {
                if (feature.properties.id === plot.id) {
                    map.setFeatureState({ source: "searchCollection", id: feature.id }, { hover: false });
                }
            });
        }
    }

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={accepted ? { border: "1px solid #14ce4e" } : null} className="plot-list-item-container" onClick={onClick}>
            <div className="left">
                <img width={34} height={34} style={{ padding: '0px 24px' }} src={plotIcon} alt="Icone de parcelle" />
                <div className="plot-info">
                    <h3 style={{ margin: 0 }}>Parcelle {(plot.prefixe === "000" || plot.prefixe === undefined ? "" : plot.prefixe + " ") + plot.section + " " + plot.numero}</h3>
                    <span style={{ fontSize: 12 }}><strong>{plot.contenance} m2</strong> - {capitalizeWords(plot.commune) + " (" + plot.code_insee + ")"}</span>
                </div>
            </div>
            <div className="right">
                {type === "search"
                    ? <IconButton onClick={handleAcceptClick} icon='fa-solid fa-check' color={accepted ? "#14ce4e" : null} />
                    : <IconButton onClick={handleFavClick} icon={plot.fav === 0 ? "fa-regular fa-bookmark" : "fa-solid fa-bookmark"} />
                }
                <IconButton onClick={handleBboxClick} icon='fa-solid fa-location-dot' />
                <IconButton onClick={handleGMapsClick} icon='fa-solid fa-street-view' />
                <FontAwesomeIcon icon='fa-solid fa-chevron-right' style={{ margin: '0px 8px' }} color='rgb(var(--black-6))' />
            </div>
        </div >
    )
}

export default PlotListItem;