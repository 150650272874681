import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function IconButton({ icon, onClick, color }) {

    return (
        <div style={color ? { borderColor: color } : null} className='icon-button-container' onClick={onClick}>
            <FontAwesomeIcon icon={icon} color={color ? color : 'rgb(var(--primary))'} />
        </div>
    )
}

export default IconButton;