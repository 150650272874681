import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import Button from "../components/button";
import NoResults from "../components/no-results";
import CadastreServices from "../services/cadastre";
import * as turf from '@turf/turf';
import PlotListItem from '../components/plot-list-item';

import '../style/collection.css'
import { useGlobalState } from "./app-container";
import CollectionServices from "../services/collections";
import { useAuth0 } from "@auth0/auth0-react";
import { useMap } from "react-map-gl";
import UtilsServices from "../services/utils";
import PEB from "../assets/geojson/peb.json"
import GARES from "../assets/geojson/liste-des-gares.json"
import METRO from "../assets/geojson/stations-de-metro.json"
import TRAM from "../assets/geojson/stations-de-tramway.json"

const date = new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' });

function NewCollection() {

    useEffect(() => {
        setSearchCollection(null);
        setOpenedCollection(null);

        if (parcelle !== null) {
            CadastreServices.getCityByInsee(parcelle.commune).then((data) => {
                if (data) {
                    let options = []
                    data.forEach((city) => {
                        options.push({ value: city.code, label: city.nom + " (" + city.codesPostaux[0] + ")", cp: city.codesPostaux[0] })
                    })
                    handleCityChange(options.find((city) => { return city.value === parcelle.commune }), { action: "select-option" })

                    CadastreServices.getPlotDetails(parcelle.numero, parcelle.section, parcelle.prefixe, null, parcelle.commune).then((data) => {
                        console.log(data)
                        if (data && data.features && data.features.length > 0) {

                            let plot = data.features[0];

                            let mainParcelle = {
                                id: parcelle.id,
                                fav: 1,
                                memo: "",
                                numero: parcelle.numero,
                                section: parcelle.section,
                                aprente: parcelle.aprente,
                                prefixe: parcelle.prefixe,
                                contenance: parcelle.contenance,
                                code_insee: parcelle.commune,
                                commune: options.find((city) => { return city.value === parcelle.commune }).label.replace(/'/g, " "),
                                creation_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
                                plot_geometry: plot.geometry,
                                buildings_geometry: [],
                                bbox: plot.properties.bbox,
                                zones: [],
                                accepted: true
                            }
                            setSearchCollection({ plots: [mainParcelle], name: collectionName })
                        }
                    })
                }
            })


        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let navigate = useNavigate();
    let { state: parcelle } = useLocation()
    const { getAccessTokenSilently } = useAuth0();

    // collection name has default value the date in the format dd/mm/yyyy
    const [collectionName, setCollectionName] = useState(date);
    // eslint-disable-next-line no-unused-vars
    const [openedCollection, setOpenedCollection] = useGlobalState('openedCollection');
    const [searchCollection, setSearchCollection] = useGlobalState('searchCollection');

    const [hasChangedTitle, setHasChangedTitle] = useState(false);
    const [selectAll, toggleSelectAll] = useState(false);

    const { map } = useMap();

    const [selectedCity, setSelectedCity] = useState({ value: '', label: '' });
    const [selectedSections, setSelectedSections] = useState('');
    const [selectedZones, setSelectedZones] = useState([]);
    const [superficie, setSuperficie] = useState("");
    const [communeFeature, setCommuneFeature] = useState(null);
    const [selectedZonePEB, setSelectedZonePEB] = useState(0);
    const [pourcentMaxPeb, setPourcentMaxPeb] = useState(0);
    const [nearPlotsEnabled, setNearPlotsEnabled] = useState(false);
    const [communesDpt, setCommunesDpt] = useState([]);
    const [maximumBati, setMaximumBati] = useState(100);
    const [maxStationDistance, setMaxStationDistance] = useState(0);
    //const [ratioBatiTerrain, setRatioBatiTerrain] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [bati, setBati] = useState(null);
    const [plu, setPlu] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [parcelles, setParcelles] = useState([]);

    const [zonesOptions, setZonesOptions] = useState([]);
    const [sectionsOptions, setSectionsOptions] = useState([]);

    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(true);

    // eslint-disable-next-line no-unused-vars
    const [communesStatus, setCommunesStatus] = useState("default");
    const [sectionsStatus, setSectionsStatus] = useState("default");
    const [zonesStatus, setZonesStatus] = useState("default");
    // eslint-disable-next-line no-unused-vars
    const [parcellesStatus, setParcellesStatus] = useState("default");

    const [isImporting, setIsImporting] = useState(false);

    const updateCollectionName = (sections, zones, city) => {
        if (!hasChangedTitle) {

            let name = "";
            if (city) name = city.split(" ")[0];
            else name = selectedCity.label.split(" ")[0];

            if (sections && sections.length > 0) {
                name += " - " + sections.join(", ");
            }
            if (zones && zones.length > 0) {
                name += " (" + zones.join(", ") + ")";
            }
            setCollectionName(name + " - " + date);
        }
    }

    const handleCityChange = (option, type) => {
        switch (type.action) {
            case "select-option":
                if (option.value !== selectedCity.value) {
                    // changement de ville    
                    setSearchCollection(null);
                    setSelectedSections([]);
                    setSelectedZones([]);

                    setSelectedCity(option)
                    updateCollectionName(null, null, option.label)

                    // récupération des sections et zones de la commune
                    setZonesStatus("fetching");
                    setSectionsStatus("fetching");
                    setCommunesStatus("fetching");

                    CadastreServices.getZonesPLUByInsee(option.value).then((data) => {
                        if (data && data.zones && data.zones.length > 0) {
                            setZonesOptions(data.zones.map((zone) => { return { value: zone.zone, label: zone.zone, libellong: zone.label } }).sort((a, b) => (a.label > b.label) ? 1 : -1))
                            setZonesStatus("available")
                            setPlu(data.plu)
                        } else {
                            setZonesOptions([])
                            setZonesStatus("none")
                        }
                        setSelectedZones([])
                    })

                    CadastreServices.getSectionsByInsee(option.value).then((data) => {
                        setSelectedSections([])
                        if (data && data.features.length > 0) {
                            setSectionsOptions(data.features.map((section) => { return { value: (section.properties.com_abs === "000" ? "" : section.properties.com_abs + ";") + section.properties.section, label: (section.properties.com_abs === "000" ? "" : section.properties.com_abs + " ") + section.properties.section } }))
                            setSectionsStatus("available")
                            if (parcelle !== null) onSectionsChange([{ value: (parcelle.prefixe === "000" ? "" : parcelle.prefixe + ";") + parcelle.section, label: (parcelle.prefixe === "000" ? "" : parcelle.prefixe + " ") + parcelle.section }], { action: "select-option" })
                        } else {
                            setSectionsOptions([])
                            setSectionsStatus("none")
                        }
                    })

                    CadastreServices.getCommuneByInsee(option.value).then((data) => {
                        if (data) {
                            setCommunesStatus("available")
                            if (parcelle === null) map.fitBounds(data.features[0].bbox, { padding: 100 });
                            setCommuneFeature(data.features[0])
                        } else {
                            setCommunesStatus("none")
                        }
                    })
                }
                break;

            case "clear":
                setSelectedCity({ value: '', label: '' });
                break;

            default:
                break;
        }
    }

    const onSectionsChange = (option, type) => {
        if (type.action === 'select-option' || type.action === 'remove-value') {
            updateCollectionName(option.map((section) => { return section.label }), selectedZones.map((typezone) => { return typezone.label }));
            setSelectedSections(option)
        } else if (type.action === 'clear') {
            setSelectedSections([])
            updateCollectionName(null, selectedZones.map((typezone) => { return typezone.label }));
        }
    }

    const loadCommunesOptions = (inputValue, callback) => {
        if (inputValue.length > 2) {
            CadastreServices.getCitiesByName(inputValue).then((data) => {
                let options = []
                data.forEach((city) => {
                    options.push({ value: city.code, label: city.nom + " (" + city.codesPostaux[0] + ")" })
                })
                callback(options)
            })
        }
    }

    const onSuperficieChange = (e) => {
        setSuperficie(e.target.value)
    }

    /*
    const onRatioBatiTerrainChange = (e) => {
        setRatioBatiTerrain(e.target.value)
    }
    */

    const onZoneChange = (option, type) => {
        if (type.action === 'select-option') {
            setSelectedZones(option)
        } else if (type.action === 'clear') setSelectedZones([])
        else if (type.action === 'remove-value') setSelectedZones(option)
    }

    const getNearPlots = (insee) => {
        if (communesDpt.length > 0) {

            let mainArea = communesDpt.find((city) => { return city.properties.id === insee })

            console.log('mainArea', mainArea)

            let nearCities = []
            communesDpt.forEach((city) => {
                if (city.properties.id !== insee) {

                    let intersection = turf.intersect(city.geometry, mainArea.geometry)
                    if (intersection !== null) {
                        nearCities.push(city)
                    }

                }
            })

            if (nearCities.length > 0) {

                let nearPlots = []
                nearCities.forEach((city) => {
                    CadastreServices.getPlotsByInsee(city.properties.id).then((data) => {
                        if (data && data.features) {
                            data.features.forEach((plot) => {

                                if (superficie === null || plot.properties.contenance > superficie) {

                                    let intersect = turf.intersect(plot.geometry, mainArea.geometry)
                                    if (intersect !== null) {
                                        plot.properties = { ...plot.properties, nom_com: city.properties.nom }
                                        nearPlots.push(plot)
                                    }

                                }

                            })
                        }
                    })
                })

                return nearPlots;

            } else {
                return "none";
            }

        } else {
            return "none"
        }
    }

    const search = () => {
        /*
        && selectedSections.length > 0
            && selectedZones.length > 0
            && selectedTypeZones.length > 0
            && superficie > 0
            && ratioBatiTerrain > 0
            */
        if (selectedCity.value.length === 5) {
            setIsSearchLoading(true)

            let nearPlots = getNearPlots(selectedCity.value)
            console.log("nearCities", nearPlots)



            CadastreServices.getPlotsByInsee(selectedCity.value).then((data) => {
                if (data && data.features) {
                    let plots = [];

                    CadastreServices.getBatiByInsee(selectedCity.value).then((batiData) => {
                        if (batiData && batiData.features) {
                            data.features.forEach((plot) => {
                                let isUnderMaxBati = maximumBati === 100 ? true : false;
                                let isBigger = superficie === null ? true : plot.properties.contenance > superficie;
                                let isInSections = true;
                                let isUnderPebRatio = selectedZonePEB === 0 ? true : false;
                                // eslint-disable-next-line
                                let nearStationExists = maxStationDistance == 0 ? true : false;

                                if (selectedSections.length > 0 && plot.properties.prefixe === "000") {
                                    // eslint-disable-next-line no-lone-blocks
                                    isInSections = selectedSections.some((section) => { { return section.value === plot.properties.section } })
                                } else if (selectedSections.length > 0 && plot.prefixe !== "000") {
                                    // eslint-disable-next-line no-lone-blocks
                                    isInSections = selectedSections.some((section) => { { return section.value.split(";")[0] === plot.properties.prefixe && section.value.split(';')[1] === plot.properties.section } })
                                }

                                //let isAboveRatio = ratioBatiTerrain === null ? true : false;
                                let isInZone = selectedZones.length === 0 ? true : false;
                                if (isBigger && isInSections) {

                                    let nearestStation = null;
                                    let minDistance = Infinity;
                                    let stationInfo = null;

                                    if (!isUnderMaxBati) {
                                        let batimentsInPlot = batiData.features.filter((batiment) => {
                                            // returns features that are inside the plot feature
                                            // batiment is a MultiPolygon
                                            // turf.booleanContains do not support MultiPolygon
                                            // so we need to check if the MultiPolygon contains the Polygon
                                            let isInside = false;
                                            batiment.geometry.coordinates.forEach((polygon) => {
                                                if (turf.booleanContains(plot.geometry, turf.polygon(polygon)))  //turf.polygon(polygon), turf.point(plot.geometry.coordinates[0])))
                                                    isInside = true;
                                            })
                                            return isInside;
                                        })
                                        let batiArea = batimentsInPlot.reduce((acc, batiment) => { return acc + turf.area(batiment) }, 0)
                                        isUnderMaxBati = (batiArea / plot.properties.contenance * 100) <= maximumBati;
                                    }

                                    if (!nearStationExists) {

                                        GARES.features.forEach((station) => {
                                            let distance = turf.distance(turf.point(station.geometry.coordinates), turf.point(plot.geometry.coordinates[0][0]), { units: 'kilometers' })
                                            if (distance < minDistance) {
                                                nearestStation = station;
                                                minDistance = distance;
                                                stationInfo = "Gare de " + station.properties.libelle;
                                            }
                                        })

                                        METRO.features.forEach((station) => {
                                            let distance = turf.distance(turf.point(station.geometry.coordinates[0]), turf.point(plot.geometry.coordinates[0][0]), { units: 'kilometers' })
                                            if (distance < minDistance) {
                                                nearestStation = station;
                                                minDistance = distance;
                                                stationInfo = "Station de métro " + station.properties.nom + " (Ligne " + station.properties.ligne + ")"
                                            }
                                        })

                                        TRAM.features.forEach((station) => {
                                            let distance = turf.distance(turf.point(station.geometry.coordinates[0]), turf.point(plot.geometry.coordinates[0][0]), { units: 'kilometers' })
                                            if (distance < minDistance) {
                                                nearestStation = station;
                                                minDistance = distance;
                                                stationInfo = "Station de tramway " + station.properties.nom + " (Ligne " + station.properties.ligne + ")"
                                            }
                                        })
                                        nearStationExists = minDistance <= maxStationDistance;
                                    }

                                    const zonesPLU = [];

                                    if (zonesStatus === "available" && selectedZones.length > 0) {


                                        plu.features.forEach((zone) => {
                                            let isInside = false;
                                            plot.geometry.coordinates[0].forEach((point) => {
                                                if (turf.booleanPointInPolygon(turf.point(point), zone)) {
                                                    isInside = true;
                                                }
                                            })
                                            if (isInside) zonesPLU.push({ label: zone.properties.libelong !== null ? zone.properties.libelong.replace(/'/g, " ") : zone.properties.libelle, typezone: zone.properties.typezone, zone: zone.properties.libelle })
                                        })

                                    }

                                    if (selectedZonePEB !== 0) {
                                        let zonePEB = null;
                                        PEB.features.forEach((zone) => {
                                            if (zone.properties.ZONE === selectedZonePEB) {

                                                if (!turf.booleanDisjoint(plot, zone)) {
                                                    let intersection = turf.intersect(zone.geometry, plot.geometry)
                                                    let intersectionArea = turf.area(intersection)
                                                    let areaRatio = Math.round(intersectionArea / turf.area(plot) * 100)

                                                    zonePEB = {
                                                        zone: zone.properties.ZONE,
                                                        ratioPeb: areaRatio
                                                    }
                                                }
                                            }
                                        })
                                        isUnderPebRatio = zonePEB === null || zonePEB.ratioPeb <= pourcentMaxPeb
                                    }

                                    if (isUnderPebRatio && isUnderMaxBati && nearStationExists) {
                                        if (zonesStatus === "available" && zonesPLU.length > 0) {

                                            if (!isInZone) {
                                                isInZone = zonesPLU.some((zone) => { return selectedZones.some((selectedZone) => { return selectedZone.value === zone.zone }) })
                                            }

                                            if (isInZone) {
                                                let parcelle = {
                                                    id: plot.properties.id,
                                                    fav: 0,
                                                    memo: "",
                                                    numero: plot.properties.numero,
                                                    section: plot.properties.section,
                                                    aprente: plot.properties.aprente,
                                                    prefixe: plot.properties.prefixe,
                                                    contenance: plot.properties.contenance,
                                                    code_insee: plot.properties.commune,
                                                    commune: communeFeature.properties.nom_com.replace(/'/g, " "),
                                                    creation_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
                                                    plot_geometry: plot.geometry,
                                                    buildings_geometry: [],
                                                    bbox: turf.bbox(plot),
                                                    zones: zonesPLU,
                                                    nearestStation: maxStationDistance === 0 ? null : { station: nearestStation, distance: minDistance },
                                                }

                                                plots.push(parcelle)
                                            }
                                            
                                        } else if(zonesStatus !== "available" && selectedZones.length === 0) {

                                            console.log("no zones")
                                            
                                            let parcelle = {
                                                id: plot.properties.id,
                                                fav: 0,
                                                memo: "",
                                                numero: plot.properties.numero,
                                                section: plot.properties.section,
                                                aprente: plot.properties.aprente,
                                                prefixe: plot.properties.prefixe,
                                                contenance: plot.properties.contenance,
                                                code_insee: plot.properties.commune,
                                                commune: communeFeature.properties.nom_com.replace(/'/g, " "),
                                                creation_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
                                                plot_geometry: plot.geometry,
                                                buildings_geometry: [],
                                                bbox: turf.bbox(plot),
                                                zones: [],
                                                nearestStation: maxStationDistance === 0 ? null : { station: stationInfo, distance: minDistance },
                                            }

                                            plots.push(parcelle)
                                        }
                                    }
                                }

                                /*
                                if (!isAboveRatio) {
                                    let batimentsInPlot = batiments.filter((batiment) => {
                                        // returns features that are inside the plot feature
                                        // batiment is a MultiPolygon
                                        // turf.booleanContains do not support MultiPolygon
                                        // so we need to check if the MultiPolygon contains the Polygon
                                        let isInside = false;
                                        batiment.geometry.coordinates.forEach((polygon) => {
                                            console.log(plot.geometry)
                                            if (turf.booleanContains(turf.polygon(polygon), turf.point(plot.geometry.coordinates)))
                                                isInside = true;
                                        })
                                        return isInside;
                                    })
                                    let superficieBati = 0;
                                    batimentsInPlot.forEach((batiment) => {
                                        superficieBati += turf.area(batiment)
                                    })
                                    isAboveRatio = (superficieBati / plot.properties.contenance * 100) > ratioBatiTerrain;
                                }

                                if (!isInTypeZone || !isInZone) {
                                    plu.features.forEach((zone) => {
                                        if (!isInTypeZone) {
                                            if (selectedTypeZones.some((typeZone) => { return typeZone.value === zone.properties.typezone })) {
                                                plot.geometry.coordinates[0].forEach((point) => {
                                                    if (turf.booleanPointInPolygon(turf.point(point), zone)) {
                                                        isInTypeZone = true;
                                                    }
                                                })
                                            }
                                        }
                                        if (!isInZone) {
                                            if (selectedZones.some((z) => { return z.value === zone.properties.libelle })) {
                                                plot.geometry.coordinates[0].forEach((point) => {
                                                    if (turf.booleanPointInPolygon(turf.point(point), zone)) {
                                                        isInZone = true;
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                                */

                            })

                            if (nearPlots !== "none") {
                                nearPlots.forEach((plot) => {

                                    let parcelle = {
                                        id: plot.properties.id,
                                        fav: 0,
                                        memo: "",
                                        numero: plot.properties.numero,
                                        section: plot.properties.section,
                                        aprente: plot.properties.aprente,
                                        prefixe: plot.properties.prefixe,
                                        contenance: plot.properties.contenance,
                                        code_insee: plot.properties.commune,
                                        commune: plot.properties.nom_com.replace(/'/g, " "),
                                        creation_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
                                        plot_geometry: plot.geometry,
                                        buildings_geometry: [],
                                        bbox: turf.bbox(plot),
                                        zones: [],
                                    }

                                    plots.push(parcelle)

                                })

                            }

                            setSearchCollection({ plots: plots, name: collectionName })
                            setIsSearchLoading(false)
                            setBati(batiData.features)
                            setParcelles(data.features)
                        } else {
                            // TODO : afficher toast d'erreur
                            setIsSearchLoading(false)
                        }
                    })
                } else {
                    // TODO : afficher toast d'erreur
                    setIsSearchLoading(false)
                }
            })
        }
    }

    const saveCollection = () => {
        if (collectionName.length > 0) {
            if (searchCollection.plots.filter((plot) => { return plot.accepted }).length > 0) {
                let collectionToSave = {
                    name: collectionName.replace(/'/g, " "),
                    description: "",
                    plots: searchCollection.plots.filter((plot) => { return plot.accepted }),
                    sharedWith: []
                }
                getAccessTokenSilently().then((token) => {
                    CollectionServices.createCollection(collectionToSave, token).then((data) => {
                        if (data && data.uuid) {
                            setSearchCollection(null)
                        }
                    })
                })
            }
        }
    }

    const updateAccepted = (plot) => {
        setSearchCollection({ ...searchCollection, plots: searchCollection.plots.map((p) => { return p.id === plot.id ? plot : p }) })
        toggleSelectAll(searchCollection.plots.map((p) => { return p.id === plot.id ? plot : p }).filter((p) => { return p.accepted }).length > 0)

    }

    const handleBboxClick = (bbox) => {
        map.fitBounds(bbox, { padding: 100 });
    }

    const onSelectAll = () => {
        setSearchCollection({ ...searchCollection, plots: searchCollection.plots.map((p) => { return { ...p, accepted: !selectAll } }) })
        toggleSelectAll(!selectAll)
    }

    const resetSearch = () => {
        setSearchCollection(null)
        setCollectionName(date)
        setHasChangedTitle(false)
        setSelectedCity({ value: '', label: '' })
        setSelectedSections([])
        setSelectedZones([])
        setSuperficie("")
        setPourcentMaxPeb(0)
        setSelectedZonePEB("0")
    }

    const handleNearPlotsChange = (e) => {
        setNearPlotsEnabled(e.target.checked)
        if (e.target.checked) {
            CadastreServices.getCommunesByDepartement(selectedCity.value.startsWith("97") ? selectedCity.value.substring(0, 3) : selectedCity.value.substring(0, 2)).then((data) => {
                if (data && data.features && data.features.length > 0) {
                    setCommunesDpt(data.features)
                    console.log(data.features)
                } else {
                    setCommunesDpt([])
                }
            })
        }
    }

    const handleMaxBatiChange = (e) => {
        setMaximumBati(e.target.value)
    }

    const handleMaxStationDistanceChange = (e) => {
        setMaxStationDistance(e.target.value)
    }

    const handleCollectionImport = async (e) => {
        const file = e.target.files[0];
        const fileURL = URL.createObjectURL(file);
        const response = await fetch(fileURL);
        const data = await response.text();
        const lines = data.split('\r\n');
        const tempPlots = lines.map((line) => { return line.split(';') })

        let tempCollection = {
            name: "Nouvelle collection",
            description: "",
            plots: [],
            sharedWith: []
        }

        setIsImporting(true)
        for (let i = 0; i < tempPlots.length; i++) {
            let plot = tempPlots[i];
            console.log("parcelle: ", plot)
            console.log("ligne ", i)
            const data = await CadastreServices.getPlotDetails(plot[3], plot[2], plot[1].replace(/0/g, "") === "" ? "000" : plot[1], plot[0].substring(0, 2) === "97" ? plot[0].substring(0, 3) : plot[0].substring(0, 2), plot[0])
            if (data && data.features && data.features.length > 0) {
                let plot = data.features[0];
                let parcelle = {
                    id: plot.properties.idu,
                    fav: 0,
                    memo: "",
                    numero: plot.properties.numero,
                    section: plot.properties.section,
                    prefixe: plot.properties.prefixe,
                    contenance: plot.properties.contenance,
                    code_insee: plot.properties.code_insee,
                    commune: plot.properties.nom_com.replace(/'/g, " "),
                    creation_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
                    plot_geometry: plot.geometry,
                    buildings_geometry: [],
                    bbox: turf.bbox(plot),
                    zones: [],
                    accepted: true
                }
                tempCollection.plots.push(parcelle)
            } else {
                console.log("failed")
                console.log(plot)
                console.log(data)
            }
        }
        setIsImporting(false)

        console.log(tempCollection)
        setSearchCollection(tempCollection)
    }

    return (
        <div className="new-collection-container">
            <div className="new-collection-header">
                <FontAwesomeIcon icon="fa-solid fa-edit" style={{ height: "20px", color: "rgb(var(--black-8))" }} />
                <input onClick={(e) => { e.target.select() }} id="new-collection-input" value={collectionName} onChange={(e) => { setCollectionName(e.target.value); setHasChangedTitle(true) }} />
            </div>
            <div className="new-collection-body">
                <div className="new-collections-plots">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3>Critères de recherche</h3>
                        <div className="hide-filters" onClick={() => setShowFilters(!showFilters)}>
                            <label className="hide-filters" htmlFor="show-filters">{showFilters ? "Masquer les filtres" : "Afficher les filtres"}</label>
                            <input name="show-filters" style={{ marginRight: 8 }} type="checkbox" checked={showFilters} onChange={(e) => { setShowFilters(e.target.checked) }} />
                        </div>
                    </div>
                    {showFilters && (
                        <div className="new-collection-criterias">
                            <div className="row">
                                <div className="input-group commune">
                                    <label>Commune</label>
                                    <AsyncSelect className="selector" isClearable={true} onChange={handleCityChange} placeholder="Saisir une commune..." loadOptions={loadCommunesOptions} defaultOptions id="new-collection-city-input" />
                                </div>
                                <div className="input-group">
                                    <label>Surface</label>
                                    <input className="surface-input" type="number" min={0} value={superficie} onChange={onSuperficieChange} />
                                </div>
                            </div>

                            <div id="section-select-container" className="input-group full-width">
                                {sectionsStatus === "fetching" && (<div className="overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" spin /></div>)}
                                {sectionsStatus === "none" && (<div className="overlay"><FontAwesomeIcon icon="fa-solid fa-exclamation-triangle" color="orange" /><p style={{ margin: 0, fontFamily: "Source Sans Pro", fontSize: "1rem" }}>Aucune section trouvée pour cette commune.</p></div>)}
                                <label>Section</label>
                                <Select isDisabled={sectionsStatus === "default" || sectionsStatus === "fetching" || sectionsStatus === "none"} isMulti options={sectionsOptions} className="selector" onChange={onSectionsChange} value={selectedSections} isClearable={true} />
                            </div>

                            <div className="input-group full-width">
                                {zonesStatus === "fetching" && (<div className="overlay"><FontAwesomeIcon icon="fa-solid fa-spinner" spin /></div>)}
                                {zonesStatus === "none" && (<div className="overlay"><FontAwesomeIcon icon="fa-solid fa-exclamation-triangle" color="orange" /><p style={{ margin: 0, fontFamily: "Source Sans Pro", fontSize: "1rem" }}>PLU indisponible pour cette commune.</p></div>)}
                                <label>Zone PLU</label>
                                <Select
                                    isMulti
                                    options={zonesOptions}
                                    className="selector"
                                    value={selectedZones}
                                    onChange={onZoneChange}
                                    isClearable={true}
                                    isDisabled={zonesStatus === "default" || zonesStatus === "fetching" || zonesStatus === "none"}
                                />
                            </div>

                            <div className="row peb-row">
                                <div className="input-group">
                                    <label>Sous courbe PEB</label>
                                    <select onChange={(e) => setSelectedZonePEB(e.target.value)} >
                                        <option selected={selectedZonePEB === "0"} default value="0">Désactivé</option>
                                        <option selected={selectedZonePEB === "A"} value="A">A</option>
                                        <option selected={selectedZonePEB === "B"} value="B">B</option>
                                        <option selected={selectedZonePEB === "C"} value="C">C</option>
                                        <option selected={selectedZonePEB === "D"} value="D">D</option>
                                    </select>
                                </div>

                                <div className="input-group">
                                    <label>% de surface max</label>
                                    <input disabled={selectedZonePEB === "0"} type="number" value={pourcentMaxPeb} onChange={(e) => { setPourcentMaxPeb(e.target.value) }} min={0} max={100} />
                                </div>

                                <div className="input-group near-plots">
                                    <label>Parcelles frontalières</label>
                                    <input id="nearPlotsEnabled" disabled={selectedCity.value === ''} type="checkbox" value={nearPlotsEnabled} onChange={handleNearPlotsChange} />
                                </div>

                            </div>
                            <div className="row peb-row">
                                <div className="input-group">
                                    <label>Bati maximum</label>
                                    <div className="row">
                                        <input min={0} max={100} type="number" id="noBati" value={maximumBati} onChange={handleMaxBatiChange} />
                                        <span style={{ display: "flex", alignItems: "center", marginLeft: 8 }}>%</span>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <label>Transport en commun à moins de </label>
                                    <div className="row">
                                        <input min={0} max={30} type="number" id="noBati" value={maxStationDistance} onChange={handleMaxStationDistanceChange} />
                                        <span style={{ display: "flex", alignItems: "center", marginLeft: 8 }}>km</span>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', margin: "8px 0px", justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                {searchCollection && searchCollection.plots && searchCollection.plots.length > 0 && searchCollection.plots.filter((plot) => { return plot.accepted }).length > 0 && (
                                    <Button text="Sauvegarder la collection" className="success" icon="fa-solid fa-save" onClick={saveCollection} />
                                )}
                                <Button className="danger" icon="fa-solid fa-trash-can" onClick={resetSearch} />
                                {searchCollection && searchCollection.plots && searchCollection.plots.length > 0 && (
                                    <Button className={selectAll ? "danger" : "primary"} icon={"fa-solid " + (selectAll ? " fa-xmark" : " fa-check")} onClick={onSelectAll} />
                                )}
                                <label htmlFor="collection-import">
                                    <Button disabled={isImporting} className="primary" icon={"fa-solid " + (isImporting ? "fa-spinner fa-pulse" : "fa-upload")} text="Importer" />
                                </label>
                                <input type="file" id="collection-import" onChange={handleCollectionImport} accept=".csv" />
                                <Button disabled={selectedCity.value === ''} onClick={search} isLoading={isSearchLoading} text="Rechercher" icon="fa-solid fa-search" className="primary" extraStyles={{ marginRight: 0 }} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="new-collections-plots">
                    {searchCollection && searchCollection.plots && searchCollection.plots.length > 0 && (
                        <>

                            {searchCollection.plots.filter((plot) => { return plot.accepted }).length > 0 && (
                                <>
                                    <h3>Parcelles sélectionnées ({searchCollection.plots.filter((parcelle) => { return parcelle.accepted }).length})</h3>
                                    {
                                        UtilsServices.sortCollection(searchCollection.plots.filter((parcelle) => { return parcelle.accepted })).map((parcelle) => {
                                            return <PlotListItem plot={parcelle} type="search" onBboxClick={handleBboxClick} onAcceptClick={updateAccepted} accepted={parcelle.accepted} />
                                        })
                                    }
                                </>
                            )}

                            <h3>Parcelles ({searchCollection.plots.length})</h3>
                            {
                                UtilsServices.sortCollection(searchCollection.plots).map((parcelle) => {
                                    return <PlotListItem plot={parcelle} type="search" onBboxClick={handleBboxClick} onAcceptClick={updateAccepted} accepted={parcelle.accepted} />
                                })
                            }
                        </>
                    )}
                    {searchCollection && searchCollection.plots && searchCollection.plots.length === 0 && (
                        <NoResults title="Aucune parcelle" text="Votre nouvelle recherche ne contient aucune parcelle." />
                    )}
                </div>
            </div>
        </div>
    );
}

export default NewCollection;