import { useEffect, useState } from 'react'
import '../style/padel.css'
import Navbar from '../components/padel/navbar'
import PadelSelection from '../components/padel/padel-selection'
import ResByDayOfWeek from '../components/padel/res-by-dow'
import PadelServices from '../services/padel'
import ResByTimeslot from '../components/padel/res-by-timeslot'
import FillRate from '../components/padel/fill-rate'
//import ResByWeek from '../components/padel/res-by-week'
import AveragePrice from '../components/padel/avg-price'
import CourtAmount from '../components/padel/court-amount'
import CourtType from '../components/padel/court-type'
import ClubView from '../components/padel/club'
import padelClubList from '../assets/padel-club-list.json'
import UtilsServices from '../services/utils'

function Padel() {

    const items = [
        { id: 0, label: "Réservations", icon: "fa-solid fa-receipt" },
        { id: 1, label: "Général", icon: "fa-solid fa-chart-simple" },
        { id: 2, label: "Clubs", icon: "fa-solid fa-house" },
        { id: 3, label: "Export", icon: "fa-solid fa-download" },
    ]

    const sorter = {
        "Lundi": 0,
        "Mardi": 1,
        "Mercredi": 2,
        "Jeudi": 3,
        "Vendredi": 4,
        "Samedi": 5,
        "Dimanche": 6
    }

    const [activeTab, setActiveTab] = useState(0)
    const [selectedClubs, setSelectedClubs] = useState([])
    const [preSelectedClubs, setPreSelectedClubs] = useState([])
    const [preSelectedInterval, setPreSelectedInterval] = useState({ start: new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString().split('/').reverse().join('-'), end: new Date().toLocaleDateString().split('/').reverse().join('-') })
    const [interval, setInterval] = useState({ start: new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString().split('/').reverse().join('-'), end: new Date().toLocaleDateString().split('/').reverse().join('-') })
    const [containerWidth, setContainerWidth] = useState(0)
    const [availableTimeslots, setAvailableTimeslots] = useState({})

    // data fetched from the API
    const [resByDowData, setResByDowData] = useState([])
    const [resByTimeslotData, setResByTimeslotData] = useState([])
    const [outdoorResByTimeslotData, setOutdoorResByTimeslotData] = useState([])
    const [fillRateData, setFillRateData] = useState([])
    //const [resByWeekData, setResByWeekData] = useState([])

    // data fetching state for each graph
    const [isResByDowRefreshing, setIsResByDowRefreshing] = useState(false)
    const [isResByTimeslotRefreshing, setIsResByTimeslotRefreshing] = useState(false)
    const [isOutdoorResByTimeslotRefreshing, setIsOutdoorResByTimeslotRefreshing] = useState(false)
    const [isFillRateRefreshing, setIsFillRateRefreshing] = useState(false)
    //const [isResByWeekRefreshing, setIsResByWeekRefreshing] = useState(false)

    useEffect(() => {
        setContainerWidth(document.getElementById('padel-container').clientWidth - 214)
    }, [])

    window.addEventListener('resize', () => {
        setContainerWidth(document.getElementById('padel-container').clientWidth - 214)
    })

    const onSelectValid = (preSelectedClubs) => {
        setSelectedClubs(preSelectedClubs)
        let tempInterval = { start: preSelectedInterval.start, end: new Date(new Date(preSelectedInterval.end).setDate(new Date(preSelectedInterval.end).getDate() + 1)).toISOString().split('T')[0] }
        setInterval(tempInterval)

        refreshResByDow(preSelectedClubs.map(club => club.value), tempInterval).then(res => {
            setResByDowData(res)
        })
        refreshResByTimeslot(preSelectedClubs.map(club => club.value), tempInterval, true).then(res => setResByTimeslotData(res))
        refreshResByTimeslot(preSelectedClubs.map(club => club.value), tempInterval, false).then(res => setOutdoorResByTimeslotData(res))
        refreshFillRate(preSelectedClubs.map(club => club.value), tempInterval).then(res => setFillRateData(res))
        //refreshResByWeek(preSelectedClubs.map(club => club.value), preSelectedInterval).then(res => setResByWeekData(res))

        if (preSelectedClubs.length === 1) {
            let currentClub = padelClubList.find(club => parseInt(club.id) === preSelectedClubs[0].value)
            let intervalDayCount = Math.floor((new Date(tempInterval.end) - new Date(tempInterval.start)) / (1000 * 60 * 60 * 24))
            let dowCount = UtilsServices.getDaysGroupedByDayOfWeek(preSelectedInterval.start, preSelectedInterval.end)
            let totalTimeslots = 0
            for (let dow in dowCount) {
                totalTimeslots += currentClub.availableTimeslots[dow] * dowCount[dow]
            }
            setAvailableTimeslots({
                dayCount: intervalDayCount,
                totalTimeslots: totalTimeslots
            })
        } else {
            setAvailableTimeslots({})
        }
    }

    const loadClub = (id) => {
        setActiveTab(0)
        setPreSelectedClubs([{ value: id, label: PadelServices.getClubNameFromId(id) }])
        setSelectedClubs([{ value: id, label: PadelServices.getClubNameFromId(id) }])
        onSelectValid([{ value: id, label: PadelServices.getClubNameFromId(id) }])
    }

    /*
    const refreshResByWeek = async (clubIds, interval) => {
        setIsResByWeekRefreshing(true)
        let clubData = []
        if (!clubIds || clubIds.length === 0) {
            await PadelServices.getResByWeek(null, interval).then(res => {
                if (res.length > 0) {
                    for (let j = 0; j < res.length; j++) {
                        let index = clubData.findIndex(item => item.yearweek === res[j].year_week)
                        if (index === -1) {
                            clubData.push({ yearweek: res[j].year_week, "Tout club": res[j].reservation_amount })
                        } else {
                            clubData[index] = { ...clubData[index], "Tout club": res[j].reservation_amount }
                        }
                    }
                }
            })
        }
        for (let i = 0; i < clubIds.length; i++) {
            await PadelServices.getResByWeek(clubIds[i], interval).then(res => {
                if (res.length > 0) {
                    for (let j = 0; j < res.length; j++) {
                        let index = clubData.findIndex(item => item.yearweek === res[j].year_week)
                        if (index === -1) {
                            clubData.push({ yearweek: res[j].year_week, [PadelServices.getClubNameFromId(clubIds[i])]: res[j].reservation_amount })
                        } else {
                            clubData[index] = { ...clubData[index], [PadelServices.getClubNameFromId(clubIds[i])]: res[j].reservation_amount }
                        }
                    }
                }
            })
        }
        let finalData = []
        for (let i = 0; i < clubData.length; i++) {
            let data = clubData[i]
            let year = data.yearweek.toString().substring(0, 4)
            let week = data.yearweek.toString().substring(4)
            data.yearweek = new Date(year, 0, (1 + (week) * 7)).toLocaleDateString()
            finalData.push(data)
        }
        setIsResByWeekRefreshing(false)
        return finalData
    }
    */

    const refreshFillRate = async (clubIds, interval) => {
        setIsFillRateRefreshing(true)
        let clubData = []
        await PadelServices.getFillRate((clubIds && clubIds.length > 0) ? clubIds : null, interval).then(res => {
            if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                    clubData.push({ name: PadelServices.getClubNameFromId(res[i].id), Remplissage: Math.round((res[i].booked / res[i].max_reservations) * 100) })
                }
            }
        })
        setIsFillRateRefreshing(false)
        return clubData
    }


    const refreshResByTimeslot = async (clubIds, interval, indoor) => {
        if (indoor) setIsResByTimeslotRefreshing(true)
        else setIsOutdoorResByTimeslotRefreshing(true)

        let data = []

        if (!clubIds || clubIds.length === 0) {
            await PadelServices.getResByTimeslot(null, interval, indoor).then(res => {
                if (res.length > 0) {
                    for (let j = 0; j < res.length; j++) {
                        data.push({ "Créneau": res[j].creneau, "Tout club": res[j].reservations_count })
                    }
                }
            })
        } else {
            for (let i = 0; i < clubIds.length; i++) {
                await PadelServices.getResByTimeslot(clubIds[i], interval, indoor).then(res => {
                    if (res.length > 0) {
                        for (let j = 0; j < res.length; j++) {
                            let indexToUpdate = data.findIndex(item => item["Créneau"] === res[j].creneau)
                            if (indexToUpdate === -1) {
                                data.push({ "Créneau": res[j].creneau, [PadelServices.getClubNameFromId(clubIds[i])]: res[j].reservations_count })
                            } else {
                                data[indexToUpdate] = { ...data[indexToUpdate], [PadelServices.getClubNameFromId(clubIds[i])]: res[j].reservations_count }
                            }
                        }
                    }
                })
            }
        }

        /*
        let clubData = [{ timeslot: "07:30" }, { timeslot: "08:00" }, { timeslot: "08:30" }, { timeslot: "09:00" }, { timeslot: "09:30" }, { timeslot: "10:00" }, { timeslot: "10:30" }, { timeslot: "11:00" }, { timeslot: "11:30" }, { timeslot: "12:00" }, { timeslot: "12:30" }, { timeslot: "13:00" }, { timeslot: "13:30" }, { timeslot: "14:00" }, { timeslot: "14:30" }, { timeslot: "15:00" }, { timeslot: "15:30" }, { timeslot: "16:00" }, { timeslot: "16:30" }, { timeslot: "17:00" }, { timeslot: "17:30" }, { timeslot: "18:00" }, { timeslot: "18:30" }, { timeslot: "19:00" }, { timeslot: "19:30" }, { timeslot: "20:00" }, { timeslot: "20:30" }, { timeslot: "21:00" }, { timeslot: "21:30" }, { timeslot: "22:00" }, { timeslot: "22:30" }]
        if (!clubIds || clubIds.length === 0) {
            await PadelServices.getResByTimeslot(null, interval).then(res => {
                if (res.length > 0) {
                    for (let j = 0; j < res.length; j++) {
                        let index = clubData.findIndex(item => item.timeslot === res[j].creneau)
                        clubData[index] = { ...clubData[index], "Tout club": res[j].reservations_count }
                    }
                }
            })
        }
        for (let i = 0; i < clubIds.length; i++) {
            await PadelServices.getResByTimeslot(clubIds[i], interval).then(res => {
                if (res.length > 0) {
                    for (let j = 0; j < res.length; j++) {
                        let index = clubData.findIndex(item => item.timeslot === res[j].creneau)
                        clubData[index] = { ...clubData[index], [PadelServices.getClubNameFromId(clubIds[i])]: res[j].reservations_count }
                    }
                }
            })
        }
        let finalData = []
        for (let i = 0; i < clubData.length; i++) {
            // if property count is 1, it means there is no data for this timeslot
            if (Object.keys(clubData[i]).length !== 1) {
                finalData.push(clubData[i])
            }
        }
        */


        data.sort((a, b) => {
            let timeA = a["Créneau"].split(':').join('')
            let timeB = b["Créneau"].split(':').join('')
            return timeA - timeB
        })

        console.log(data)

        if (indoor) setIsResByTimeslotRefreshing(false)
        else setIsOutdoorResByTimeslotRefreshing(false)
        return data
    }

    const refreshResByDow = async (clubIds, interval) => {
        setIsResByDowRefreshing(true)
        let data = []
        await PadelServices.getResByDOW(clubIds, interval).then(res => {
            if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                    if (clubIds && clubIds.length > 0) {
                        let dailyData = { day: res[i].day }
                        for (let club = 0; club < clubIds.length; club++) {
                            if (res[i][clubIds[club]]) {
                                dailyData = {
                                    ...dailyData,
                                    [PadelServices.getClubNameFromId(clubIds[club])]: res[i][clubIds[club]]
                                }
                            }
                        }
                        data.push(dailyData)
                    } else {
                        data.push({
                            day: res[i].day,
                            "Tout club": res[i].reservation_count
                        })
                    }
                    data.sort((a, b) => {
                        return sorter[a.day] - sorter[b.day]
                    })
                }

                /*
                for (let day = 0; day < res.length; day++) {
                    if (clubIds && clubIds.length > 0) {
                        for (let club = 0; club < clubIds.length; club++) {
                            let clubName = PadelServices.getClubNameFromId(clubIds[club])
                            let reservationAmount = res[day][clubIds[club]]
                            data[day] = { ...data[day], [clubName]: reservationAmount }
                        }
                    } else {
                        data[day] = { ...data[day], "Tout club": res[day].reservation_count }
                    }
                }
                */
            }
        })
        setIsResByDowRefreshing(false)
        return data
    }

    return (
        <div className='padel-page'>
            <Navbar items={items} onChange={setActiveTab} activeTab={items[activeTab]} />
            <div id="padel-container" className="padel-container">
                <PadelSelection availableTimeslots={availableTimeslots} onValid={() => onSelectValid(preSelectedClubs)} interval={preSelectedInterval} onIntervalChange={(start, end) => { setPreSelectedInterval({ start: start, end: end }) }} selectedClubs={preSelectedClubs} onClubChange={setPreSelectedClubs} />

                {activeTab === 0 && (
                    <>
                        <div className="padel-row">
                            <ResByDayOfWeek onRefresh={() => refreshResByDow(selectedClubs.map(club => club.value), interval)} refreshing={isResByDowRefreshing} width={(containerWidth / 2.4) - 48} clubIds={selectedClubs.map(club => club.value)} data={resByDowData} />
                            <ResByTimeslot indoor={true} onRefresh={() => refreshResByTimeslot(selectedClubs.map(club => club.value), interval, true)} refreshing={isResByTimeslotRefreshing} width={(containerWidth / 1.715) - 48} clubIds={selectedClubs.map(club => club.value)} data={resByTimeslotData} />
                        </div>
                        <div className="padel-row">
                            <FillRate onRefresh={() => refreshFillRate(selectedClubs.map(club => club.value), interval)} refreshing={isFillRateRefreshing} width={(containerWidth / 6) - 48} clubIds={selectedClubs.map(club => club.value)} data={fillRateData} />
                            <CourtType width={(containerWidth / 4) - 48} onClubClick={loadClub} />
                            <ResByTimeslot indoor={false} onRefresh={() => refreshResByTimeslot(selectedClubs.map(club => club.value), interval, false)} refreshing={isOutdoorResByTimeslotRefreshing} width={(containerWidth / 1.715) - 48} clubIds={selectedClubs.map(club => club.value)} data={outdoorResByTimeslotData} />
                        </div>
                    </>
                )}

                {activeTab === 1 && (
                    <>
                        <div className="padel-row">
                            <AveragePrice width={(containerWidth / 3) - 48} onClubClick={loadClub} />
                            <CourtAmount width={(containerWidth / 3) - 48} onClubClick={loadClub} />
                            <CourtType width={(containerWidth / 3) - 48} onClubClick={loadClub} />
                        </div>
                    </>
                )}

                {activeTab === 2 && (
                    <ClubView />
                )}
            </div>
        </div>
    )

    /*
    const buildClubList = () => {
        let clubs = []
        for (let courtIndex = 0; courtIndex < clubList.length; courtIndex++) {
            let clubId = clubList[courtIndex].clubId
            let clubName = clubList[courtIndex].clubName
            let CP = clubList[courtIndex].CP
            let URL = clubList[courtIndex].URL
            let weeklySchedule = JSON.parse(clubList[courtIndex].courtSchedule)
            let dimanche = weeklySchedule[0].length
            let lundi = weeklySchedule[1].length
            let mardi = weeklySchedule[2].length
            let mercredi = weeklySchedule[3].length
            let jeudi = weeklySchedule[4].length
            let vendredi = weeklySchedule[5].length
            let samedi = weeklySchedule[6].length
            let weeklyTotal = dimanche + lundi + mardi + mercredi + jeudi + vendredi + samedi

            //if the club id is not in the list, add it
            if (clubs.findIndex(club => club.id === clubId) === -1) {
                clubs.push({
                    id: clubId,
                    name: clubName,
                    CP: CP,
                    URL: URL,
                    availableTimeslots: {
                        dimanche: dimanche,
                        lundi: lundi,
                        mardi: mardi,
                        mercredi: mercredi,
                        jeudi: jeudi,
                        vendredi: vendredi,
                        samedi: samedi,
                        weeklyTotal: weeklyTotal
                    }
                })
            } else {
                //if the club id is already in the list, update the available timeslots
                let index = clubs.findIndex(club => club.id === clubId)
                clubs[index].availableTimeslots.dimanche += dimanche
                clubs[index].availableTimeslots.lundi += lundi
                clubs[index].availableTimeslots.mardi += mardi
                clubs[index].availableTimeslots.mercredi += mercredi
                clubs[index].availableTimeslots.jeudi += jeudi
                clubs[index].availableTimeslots.vendredi += vendredi
                clubs[index].availableTimeslots.samedi += samedi
                clubs[index].availableTimeslots.weeklyTotal += weeklyTotal
            }
        }

        console.log("CLUBS", clubs)
    }

    buildClubList()
    */

}

// function to fetch data
// TODO : corriger coté back le cas "tout club"


export default Padel

